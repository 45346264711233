import {useDropzone} from "react-dropzone";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
    borderWidth: 1,
    borderRadius: 1,
    borderColor: 'rgba(133, 133, 133, 0.87)',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function ImageDropZone({onDrop, coverImage}) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop, accept: {
            'image/*': []
        }
    });

    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <Grid item xs={12}>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                {coverImage && (
                    <React.Fragment>
                        <Card>
                            <CardMedia
                                component="img"
                                sx={{width: {xs: 160, md: 320}}}
                                alt={"Playlist Cover"}
                                src={coverImage.preview}
                                // Revoke data uri after image is loaded
                                onLoad={() => {
                                    URL.revokeObjectURL(coverImage.preview)
                                }}
                            />
                        </Card>
                    </React.Fragment>
                )}
                {!coverImage && (
                    <React.Fragment>
                        <Box display={{xs: 'none', sm: 'flex'}}>Drag 'n' Drop</Box>
                        <Box>Click to select cover image!</Box>
                    </React.Fragment>
                )}
            </div>
        </Grid>
    );
}

export function MediaDropZone({onDrop}) {
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop, accept: {
            'audio/*': []
        }
    });

    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <Grid item xs={12}>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <React.Fragment>
                    <Box display={{xs: 'none', sm: 'flex'}}>Drag 'n' Drop</Box>
                    <Box>Click to select media!</Box>
                </React.Fragment>
            </div>
        </Grid>
    );
}