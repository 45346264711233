import * as React from 'react';
import {ApiClient} from "./api/ApiClient";
import {LogtoGlobals} from "./index";

const AxiosInterceptor = ({ children }) => {
    const logtoGlobals = React.useContext(LogtoGlobals);
    const [isSet, setIsSet] = React.useState(false)

    React.useEffect(() => {
        const requestCallback = async request => {
            const authenticated = await logtoGlobals.client.isAuthenticated();
            if (authenticated) {
                try {
                    const token = await logtoGlobals.client.getAccessToken(process.env.REACT_APP_RESOURCE);
                    if (token) {
                        request.headers["Authorization"] = 'Bearer ' + token;
                    }
                } catch (e) {
                    console.log(e);
                    await logtoGlobals.client.signIn(window.location.origin + '/callback');
                }
            }

            return request;
        }

        const errorCallback = error => {
            console.log(error);
            return Promise.reject(error);
        }

        const interceptor = ApiClient.interceptors.request.use(requestCallback, errorCallback);

        setIsSet(true);
        return () => ApiClient.interceptors.request.eject(interceptor);

    }, [])

    return isSet && children;
}

export { AxiosInterceptor }
