import TinyText from "./TinyText";
import Duration from "./Duration";
import * as React from "react";
import {formatArtistAlbum} from "./CommonFormats";
import {getMedia, patchMedia} from "./api/MediaService";
import {Avatar, Badge, Box, Tooltip} from "@mui/material";
import {MusicNote, TouchApp} from "@mui/icons-material";
import {ApplicationGlobals} from "./App";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import * as PageConstants from "./PageConstants";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ShareIcon from "@mui/icons-material/Share";
import ReplyIcon from '@mui/icons-material/Reply';
import AlertMessage from "./AlertMessage";
import imageResize from "./ImageResize";
import {addRecentlyListened, getPlaylist, patchLastMediaPlaylist} from "./api/PlaylistService";
import {buildPlayerInfo} from "./MediaControl";
import {styled} from "@mui/material/styles";

export default function MediaPage({selectedIndex, setSelectedIndex, setLastFocusedMediaPublicId}) {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const player = applicationGlobals.contexts.player;
    const {pid, mid} = useParams();
    const [media, setMedia] = React.useState();
    const [mediaImageFile, setMediaImageFile] = React.useState(null);
    const [mediaName, setMediaName] = React.useState(null);
    const [mediaArtist, setMediaArtist] = React.useState(null);
    const [mediaAlbum, setMediaAlbum] = React.useState(null);
    const [editState, setEditState] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [mediaOwner, setMediaOwner] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    // playing media
    const [mediaInPlaylist, setMediaInPlaylist] = React.useState(false);
    const [playlistState, setPlaylistState] = React.useState(null);
    const [currentTrackId, setCurrentTrackId] = React.useState(null);
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [resumed, setResumed] = React.useState(false);
    const isPlaying = searchParams.get('play') === 'true';

    React.useEffect(() => {
        if (pid && currentTrackId && mid !== currentTrackId) {
            return navigate({pathname: '/playlist/' + pid + '/media/' + currentTrackId}, {replace: true});
        }
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.MEDIA);
        if (pid) {
            setMediaInPlaylist(true);
        }
        loadMedia(mid);
        if (pid) {
            getPlaylist(pid).then(result => {
                const playlistResponse = result.data;
                setPlaylistState(playlistResponse);
            });
        }

        if (mediaImageFile) {
            // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
            return () => {
                URL.revokeObjectURL(mediaImageFile);
                setMediaImageFile(null);
            }
        }
    }, [mid, currentTrackId]);

    React.useEffect(() => {
        if (isPlaying && !resumed) {
            if ((media && pid && playlistState) || (media && !pid)) {
                setResumed(true);
                handleListItemClick();
            }
        }
    }, [media, pid, playlistState, isPlaying, resumed]);

    const handleListItemClick = (event, index) => {
        if (playlistState) {
            if (index === undefined) {
                // check currently playing playlist
                if (player.playerState.currentPlaylist && player.playerState.currentPlaylist.id === pid) {
                    for (var i = 0; i < player.playerState.currentPlaylist.medias.length; i++) {
                        if (player.playerState.currentPlaylist.medias[i].id === mid) {
                            index = i;
                            break;
                        }
                    }
                }
                // check loaded playlist
                if (index == null) {
                    for (var i = 0; i < playlistState.medias.length; i++) {
                        if (playlistState.medias[i].id === mid) {
                            index = i;
                            break;
                        }
                    }
                }
            }

            if (index === undefined) {
                console.log("media id not found in playlist");
                return;
            }
            setSelectedIndex(index);
            const currentTrack = playlistState.medias[index];
            if (applicationGlobals.contexts.auth.authState.loggedIn) {
                setLastFocusedMediaPublicId(currentTrack.id);
                patchLastMediaPlaylist(pid, currentTrack.id);
            }
            currentTrack.playerInfo = buildPlayerInfo(currentTrack, index, handleListItemClick);
            player.setPlayerState({
                currentTrack: currentTrack,
                currentPlaylist: playlistState,
                currentPlaylistMedia: playlistState.medias,
            });
            addRecentlyListened(pid);
            setCurrentTrackId(currentTrack.id);
        } else {
            let currentTrack = media;
            currentTrack.playerInfo = buildPlayerInfo(currentTrack, 0, handleListItemClick);
            player.setPlayerState({
                currentTrack: currentTrack,
                currentPlaylist: null,
                currentPlaylistMedia: [currentTrack],
            });
        }
    };

    function loadMedia(id) {
        getMedia(id).then(response => {
            setMedia(response.data);
            setMediaName(response.data.name);
            setMediaArtist(response.data.artist);
            setMediaAlbum(response.data.album);
            setMediaOwner(response.data.ownerAccountId === applicationGlobals.contexts.auth.authState.accountId);
        }).catch(reason => {
            setError(true);
        }).finally(() => {
            setLoading(false);
        });
    }
    function handleEnterEditState() {
        setEditState(true);
    }
    function handleMediaInputName(event) {
        setMediaName(event.target.value);
    }

    function handleMediaInputArtist(event) {
        setMediaArtist(event.target.value);
    }

    function handleMediaInputAlbum(event) {
        setMediaAlbum(event.target.value);
    }

    function handleInputCover(event) {
        imageResize(event.target.files[0], 460, 460, (resizedImageFile) => {
            setMediaImageFile(resizedImageFile);
        });
    }
    function handleCancelEdit() {
        setEditState(false);
        loadMedia(mid);
    }
    function handleSaveEditChanges() {
        setEditState(false);
        setLoading(true);
        async function updateMedias() {
            const data = new FormData();
            data.append('coverImage', mediaImageFile);
            if (mediaName) {
                data.append('name', mediaName);
            }
            if (mediaArtist) {
                data.append('album', mediaArtist);
            }
            if (mediaAlbum) {
                data.append('artist', mediaAlbum);
            }
            const response = await patchMedia(media.id, data).catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(error.message);
                }
            }).finally(()=> {
                setLoading(false);
            });
        }

        updateMedias().then(() => loadMedia(mid));
    }

    const handleShareClick = (event) => {
        const mediaUri = window.location.href;
        if (navigator.share) {
            navigator.share({
                title: 'Share ' + media.name,
                url: mediaUri
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            navigator.clipboard.writeText(mediaUri)
                .then(() => {
                    setAlertOpen(true);
                    setAlertMessage("Media link has been copied!");
                }).catch(console.error);
        }
    }
    return (
        <Container sx={{marginBottom: {sm: '5em', xs: '10em'}}}>
            {loading && (
                <TinyText sx={{textDecoration: 'none', color: 'white', paddingTop: '1em', display: 'inline-block'}}>
                    Loading...
                </TinyText>
            )}
            {!loading && error && (
                <span>
                    <TinyText sx={{textDecoration: 'none', color: 'red', paddingTop: '1em', display: 'inline-block'}}>
                        Failed to Load
                    </TinyText>
                </span>
            )}
            {!loading && !error && media !== null && (
                <React.Fragment>
                    <Typography variant="h5" mt={1} mb={2} component="div">
                        <Stack direction="row" style={{paddingBottom: '0.5em', marginBottom: '0.5em'}}>
                            <Stack direction="column">
                                <span>{mediaName}
                                    {mediaOwner && !editState && (
                                        <Tooltip title="Edit Playlists" sx={{marginLeft: 1}}>
                                            <IconButton size="small" color="inherit" onClick={handleEnterEditState}>
                                                <SettingsIcon sx={{color: 'gray'}}/>
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {mediaOwner && editState && (
                                        <React.Fragment>
                                            <Tooltip title="Cancel Changes" sx={{marginLeft: 1}}>
                                                <IconButton size="small" color="inherit" onClick={handleCancelEdit}>
                                                    <CloseIcon sx={{color: 'red'}}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Save Changes" sx={{marginLeft: 1}}>
                                                <IconButton size="small" color="inherit" onClick={handleSaveEditChanges}>
                                                    <CheckIcon sx={{color: 'green'}}/>
                                                </IconButton>
                                            </Tooltip>
                                        </React.Fragment>
                                    )}
                                </span>
                                <span>
                                     <TinyText component={Link} to={"/account/" + media.ownerAccountId} sx={{textDecoration: 'none', color: 'white', paddingTop: '1em', display: 'inline-block'}}>
                                    by {media.ownerName}
                                    </TinyText>
                                </span>

                            </Stack>
                        </Stack>
                        {!editState && (
                            <Stack direction="row">
                                {!editState && mediaInPlaylist && (
                                    <TinyText>
                                        <Chip icon={<ReplyIcon/>}
                                              sx={{paddingLeft: 1, paddingRight: 1, marginRight: 1, textTransform: 'none'}}
                                              component={Link} to={"/playlist/" + pid}
                                              label="Back to playlist"
                                              variant="outlined" clickable/>
                                    </TinyText>
                                )}
                                <TinyText>
                                    <Chip icon={<ShareIcon/>}
                                          sx={{paddingLeft: 1, paddingRight: 1, marginLeft: 0, textTransform: 'none'}}
                                          component={Button} onClick={(event) => handleShareClick(event)}
                                          label="Share"
                                          variant="outlined" clickable/>
                                </TinyText>
                            </Stack>
                        )}
                    </Typography>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        paddingTop={1}
                    >
                        {editState && (
                            <React.Fragment>
                                <input
                                    hidden
                                    accept=".jpeg,.jpg,.png,.tiff"
                                    id="media-cover"
                                    onChange={handleInputCover}
                                    multiple
                                    type="file"
                                />
                                <label htmlFor="media-cover">
                                    {mediaImageFile && (
                                        <Avatar sx={{height: '250px', width: '250px'}}
                                                alt={media.name}
                                                src={URL.createObjectURL(mediaImageFile)}
                                                variant={'square'}>
                                            <Box sx={{p:1, display:'flex', justifyContent:'center'}}>
                                                <TinyText><TouchApp></TouchApp></TinyText>
                                            </Box>
                                        </Avatar>
                                    )}
                                    {!mediaImageFile && (
                                        <Avatar sx={{height: '250px', width: '250px'}}
                                                alt={media.name}
                                                src={media.coverImageUri ? process.env.REACT_APP_BASE_URL + '/files/image/' + media.coverImageUri : null}
                                                variant={'square'}>
                                            <Box sx={{p:1, display:'flex', justifyContent:'center'}}>
                                                <TinyText><TouchApp></TouchApp></TinyText>
                                            </Box>
                                        </Avatar>
                                    )}

                                </label>
                            </React.Fragment>
                        )}
                        {!editState && (
                            <Button onClick={(event) => handleListItemClick(event)}>
                                <StyledBadge overlap="circular"
                                           variant="dot" invisible={player.playerState.currentTrack?.id === mid}
                                sx={{'&::after': {
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        animation: `${player.playerState.currentTrack?.id === mid ? "ripple 2.4s infinite ease-in-out" : ""}`,
                                        display: `${player.playerState.currentTrack?.id === mid ? "inline" : "none"}`,
                                        border: '1px solid currentColor',
                                        content: '""',
                                    },}}>
                                    <Avatar sx={{height: '250px', width: '250px'}}
                                            alt={media.name}
                                            src={media.coverImageUri ? process.env.REACT_APP_BASE_URL + '/files/image/' + media.coverImageUri : null}
                                            variant={'square'}>
                                        <MusicNote/>
                                    </Avatar>
                                </StyledBadge>
                            </Button>
                        )}
                        <TinyText mt={1} mb={1} component="div" sx={{fontSize: "1em"}}>
                            {!editState && (
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Duration seconds={media.duration}/>
                                    <span>{formatArtistAlbum(media)}</span>
                                </Stack>
                            )}
                        </TinyText>
                        <Typography variant="h5" mt={1} mb={1} component="div">
                            {editState && (
                                <TextField id="name-outlined-input" label="Name" variant="outlined" value={mediaName}
                                           onInput={handleMediaInputName}/>
                            )}
                            {!editState && (
                                <span>{media.name}</span>
                            )}
                        </Typography>
                        {editState && (
                            <Stack direction="row">
                                <TextField id="artist-outlined-input" label="Artist" variant="outlined" value={mediaArtist}
                                           onInput={handleMediaInputArtist}/>
                                <TextField id="album-outlined-input" label="Album" variant="outlined" value={mediaAlbum}
                                           onInput={handleMediaInputAlbum}/>
                            </Stack>
                        )}
                    </Stack>
                    <AlertMessage open={alertOpen} setOpen={setAlertOpen} message={alertMessage} time={3000}/>
                </React.Fragment>
            )}
        </Container>
    )
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        width: 0,
        height: 0,
        left: '80px',
        top: '128px',
        borderTop: '28px solid transparent',
        borderBottom: '28px solid transparent',
        borderLeft: '48px solid #e52620',
        borderRadius: '4px',
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(0.0)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(1.0)',
            opacity: 0,
        },
    },
}));
