import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {MusicNote} from "@mui/icons-material";
import {Avatar, LinearProgress} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import imageResize from "./ImageResize";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function DraggableMedia({media, mediaList, setMediaList, mediasCoverToUpdate, index, uploading, mediaListUploadPercent, removable}) {
    const [name, setName] = React.useState(media.name || '');
    const [artist, setArtist] = React.useState(media.artist || '');
    const [album, setAlbum] = React.useState(media.album || '');
    const [coverImage, setCoverImage] = React.useState(media.coverImage || '');

    function handleInputName(event) {
        media.name = event.target.value;
        setName(media.name);
        setMediaList(mediaList);
    }

    function handleInputArtist(event) {
        media.artist = event.target.value;
        setArtist(media.artist);
        setMediaList(mediaList);
    }

    function handleInputAlbum(event) {
        media.album = event.target.value;
        setAlbum(media.album);
        setMediaList(mediaList);
    }

    function handleInputCover(event) {
        imageResize(event.target.files[0], 460, 460, (resizedImageFile) => {
            media.coverImage = resizedImageFile;
            setCoverImage(media.coverImage);
            if (mediasCoverToUpdate) {
                mediasCoverToUpdate[media.id] = true;
            }
            setMediaList(mediaList);
        });
    }

    function handleRemoveMedia() {
        let trimmedList = [];
        for (let i = 0; i < mediaList.length; i++) {
            if (mediaList[i].id != media.id) {
                trimmedList.push(mediaList[i]);
            }
        }
        setMediaList(trimmedList);
    }

    return (
        <Draggable draggableId={media.draggableId} index={index} isDragDisabled={uploading}>
            {provided => (
                <Grid container ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{border: '1px solid gray', marginBottom: 1, paddingTop: 1}}>
                    <Grid item xs={12} md={1}
                          sx={{paddingLeft: 1, marginBottom: {xs: 2, md: 0}}}
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                        {index + 1}

                        <input
                            hidden
                            accept=".jpeg,.jpg,.png,.tiff"
                            id={`${'cover-' + media.draggableId}`}
                            onChange={handleInputCover}
                            disabled={uploading}
                            multiple
                            type="file"
                        />
                        <label htmlFor={`${'cover-' + media.draggableId}`}>
                            {coverImage && (
                                <Card sx={{
                                    marginLeft: 1,
                                    display: {md: 'flex'},
                                    width: '40px',
                                    height: '40px',
                                    borderRadius: 0
                                }}>
                                    <CardMedia
                                        component="img"
                                        alt={`${media.name}`}
                                        src={URL.createObjectURL(coverImage)}
                                    />
                                </Card>
                            )}
                            {!coverImage && (
                                <Avatar sx={{marginLeft: 1, display: {md: 'flex'}}}
                                        alt={`${media.name}`}
                                        src={`${media.coverImageUri ? (process.env.REACT_APP_BASE_URL + '/files/image/' + media.coverImageUri) : coverImage}`}
                                        variant={'square'}>
                                    <MusicNote/>
                                </Avatar>
                            )}
                        </label>
                    </Grid>
                    <Grid item xs={12} md={3} padding={1}>
                        <TextField fullWidth id="outlined-basic" label="Name" variant="outlined" value={name}
                                   disabled={uploading}
                                   onInput={handleInputName}/>
                    </Grid>
                    <Grid item xs={12} md={3} padding={1}>
                        <TextField fullWidth id="outlined-basic" label="Artist" variant="outlined" value={artist}
                                   disabled={uploading}
                                   onInput={handleInputArtist}/>
                    </Grid>
                    <Grid item xs={12} md={3} padding={1}>
                        <TextField fullWidth id="outlined-basic" label="Album" variant="outlined" value={album}
                                   disabled={uploading}
                                   onInput={handleInputAlbum}/>
                    </Grid>
                    <Grid item xs={12} md={2}
                          sx={{display: removable ? 'flex' : 'none', paddingRight: {xs:1, sm: 2}}}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center">
                        <IconButton aria-label="delete"
                                    disabled={uploading}
                                    onClick={handleRemoveMedia}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 1}}>
                        {uploading && (
                            <LinearProgress variant="determinate" value={mediaListUploadPercent[media.id] ? mediaListUploadPercent[media.id] : 0} />
                        )}
                    </Grid>
                </Grid>
            )}
        </Draggable>
    );
}

const DraggableMediaList = React.memo(function DraggableMediaList({mediaList, setMediaList, mediasCoverToUpdate, uploading, mediaListUploadPercent, mediaListUploaded, removable}) {
    return mediaList.map((media, index) => (!mediaListUploaded[media.id] && !media.pending &&
        <DraggableMedia media={media} mediaList={mediaList} setMediaList={setMediaList} mediasCoverToUpdate={mediasCoverToUpdate} index={index}
                        uploading={uploading}
                        mediaListUploadPercent={mediaListUploadPercent}
                        removable={removable}
                        key={media.draggableId}/>
    ));
});

function EditableMedia({mediaList, setMediaList, mediasCoverToUpdate, mediaListUploaded, mediaListUploadPercent, uploading, removable}) {

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reorderedMediaList = reorder(
            mediaList,
            result.source.index,
            result.destination.index
        );

        setMediaList(reorderedMediaList);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="list">
                {provided => (
                    <Grid item xs={12} ref={provided.innerRef} {...provided.droppableProps}>
                        <DraggableMediaList mediaList={mediaList} setMediaList={setMediaList} mediasCoverToUpdate={mediasCoverToUpdate} uploading={uploading} mediaListUploadPercent={mediaListUploadPercent} mediaListUploaded={mediaListUploaded} removable={removable}/>
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default EditableMedia;
