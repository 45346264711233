import * as React from 'react';
import Container from '@mui/material/Container';
import {useTheme} from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import Replay10Icon from '@mui/icons-material/Replay10';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import {ApplicationGlobals} from "./App";
import {Avatar, Slider, Tooltip} from "@mui/material";
import {MusicNote, VolumeDown, VolumeMute, VolumeOff, VolumeUp} from "@mui/icons-material";
import ReactPlayer from "react-player/lazy";
import Duration from "./Duration";
import Grid from "@mui/material/Grid";
import TinyText from "./TinyText";
import {updateResumeTimestamp} from "./api/MediaService";
import {getLocalVolume, setLocalVolume} from "./SettingsStorage";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import ScrollingText from "./ScrollingText";

export default function MediaControlCard() {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const currentTrack = applicationGlobals.contexts.player.playerState.currentTrack;
    const currentPlaylistMedia = applicationGlobals.contexts.player.playerState.currentPlaylistMedia;
    const setResumeMedia = applicationGlobals.contexts.resume.setResumeMedia;
    const theme = useTheme();
    const [pip, setPip] = React.useState(false);
    const [playing, setPlaying] = React.useState(true);
    const [seeking, setSeeking] = React.useState(false);
    const [controls, setControls] = React.useState(false);
    const [light, setLight] = React.useState(false);
    const [volume, setVolume] = React.useState(0.8);
    const [oldVolume, setOldVolume] = React.useState(0.0);
    const [muted, setMuted] = React.useState(false);
    const [playedSeconds, setPlayedSeconds] = React.useState(0);
    const [duration, setDuration] = React.useState(0);
    const [playbackRate, setPlaybackRate] = React.useState(1.0);
    const [loop, setLoop] = React.useState(false);
    const inputRange = React.useRef(null);
    const [previousTimeStampUpdateTime, setPreviousTimeStampUpdateTime] = React.useState(0);
    const [mediaPageUrl, setMediaPageUrl] = React.useState(null);

    React.useEffect(() => {
        const localVolume = getLocalVolume();
        if (localVolume) {
            setVolume(localVolume);
        }
        if (currentTrack != null) {
            if (applicationGlobals.contexts.player.playerState.currentPlaylist) {
                setMediaPageUrl("/playlist/" + applicationGlobals.contexts.player.playerState.currentPlaylist.id + "/media/" + currentTrack.id);
            } else {
                setMediaPageUrl("/media/" + currentTrack.id);
            }
        }
        setPlaying(true);
        setResumeMedia({
            playlist: null,
            media: null
        })
    }, [currentTrack]);

    const handlePlayPause = () => {
        setPlaying(!playing);
    }

    const clampDown = (val, clampVal) => {
        return parseFloat(val < clampVal ? 0 : val);
    }

    const handleRewind = () => {
        inputRange.current.seekTo(clampDown(playedSeconds - 10, 0));
    }

    const handlePreviousMedia = () => {
        const currentIndex = currentTrack.playerInfo.index;
        if (currentIndex > 0) {
            currentTrack.playerInfo.playlistContext.programmaticClick(null, currentTrack.playerInfo.index - 1);
        }
    }

    const handleNextMedia = () => {
        const currentIndex = currentTrack.playerInfo.index;
        if (currentIndex < (currentPlaylistMedia.length - 1)) {
            if (currentPlaylistMedia[currentTrack.playerInfo.index + 1].pending) {
                return;
            }
            currentTrack.playerInfo.playlistContext.programmaticClick(null, currentIndex + 1);
        }
    }

    const handleLooping = () => {
        setLoop(!loop);
    }

    const handleSeekChange = (event, newValue) => {
        setSeeking(true);
        setPlayedSeconds(newValue);
    };

    const handleSeekCommited = (value) => {
        setSeeking(false);
        setPreviousTimeStampUpdateTime(0);
        inputRange.current.seekTo(parseFloat(value));
    }

    const handleVolumeChange = (event, newValue) => {
        setMuted(false);
        const v = parseFloat(newValue) / 100.0;
        setVolume(v);
        setLocalVolume(v)
    }

    const handleProgress = state => {
        if (!seeking) {
            setPlayedSeconds(state.playedSeconds);
            if (currentTrack.duration >= 600) {
                const currentTime = Math.floor(Date.now() / 1000);
                if (currentTime - previousTimeStampUpdateTime >= 10) {
                    updateResumeTimestamp(currentTrack.id, Math.round(state.playedSeconds));
                    currentTrack.resumeTimestamp = Math.round(state.playedSeconds);
                    setPreviousTimeStampUpdateTime(currentTime);
                }
            }
        }
    }

    const handleEnded = () => {
        if (currentTrack.duration >= 600) {
            updateResumeTimestamp(currentTrack.id, 0);
            currentTrack.resumeTimestamp = 0;
        }
        if (currentTrack.playerInfo.index === (currentPlaylistMedia.length - 1)) {
            setPlaying(false);
        }
        if (currentTrack.playerInfo.index !== (currentPlaylistMedia.length - 1)) {
            if (currentPlaylistMedia[currentTrack.playerInfo.index + 1].pending) {
                setPlaying(false);
            }
        }
        handleNextMedia();
    }

    const handleToggleMute = () => {
        if (muted) {
            setVolume(oldVolume);
        } else {
            setOldVolume(volume);
            setVolume(0);
        }
        setMuted(!muted);
    }

    const handleDuration = (duration) => {
        setDuration(duration);
        inputRange.current.seekTo(parseFloat(currentTrack.playerInfo.resumeTimestamp));
    }

    const isNextPending = (currentTrack, currentPlaylistMedia) => {
        if (currentTrack.playerInfo.index !== (currentPlaylistMedia.length - 1)) {
            if (currentPlaylistMedia[currentTrack.playerInfo.index + 1].pending) {
                return true;
            }
        }
        return false;
    }

    let isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
        !window.MSStream;

    if (currentTrack != null) {
        return (
            <Card sx={{padding: 1, paddingLeft: 3, paddingRight: 3, borderRadius: 0}}>
                <ReactPlayer
                    ref={inputRange}
                    style={{display: 'none'}}
                    url={currentTrack.playerInfo.url}
                    pip={pip}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    // onReady={(player) => {}}
                    // onStart={() => console.log('onStart')}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    onEnded={handleEnded}
                    onError={(e) => console.log(e)}
                    playsinline={true}
                />
                <Container disableGutters>
                    <Grid container sx={{paddingBottom: {xs: '1em', lg: 0}}}>
                        <Grid item={true}
                              sx={{display: {xs: 'none', sm: 'flex'}}}
                              sm={2}
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                        >
                            <Grid>
                                <Tooltip title="Media Page">
                                    <Button component={Link} to={mediaPageUrl} sx={{padding: 0}}>
                                        <Avatar sx={{height: '100px', width: '100px'}}
                                                alt={`${currentTrack.name}`}
                                                src={currentTrack.coverImageUri ? process.env.REACT_APP_BASE_URL + '/files/image/' + currentTrack.coverImageUri : null}
                                                variant={'square'}>
                                            <MusicNote/>
                                        </Avatar>
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12} sm={8}>
                            <Grid item={true} xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={0.5}>
                                    <IconButton aria-label="previous" size="large" color="inherit"
                                                onClick={handleRewind} disabled={!playing}>
                                        <Replay10Icon/>
                                    </IconButton>
                                    <IconButton aria-label="previous" size="large" color="inherit"
                                                onClick={handlePreviousMedia} disabled={currentTrack.playerInfo.index === 0}>
                                        {theme.direction === 'rtl' ? <SkipNextIcon/> : <SkipPreviousIcon/>}
                                    </IconButton>
                                    <IconButton aria-label="play/pause" size="large" color="inherit"
                                                onClick={handlePlayPause}>
                                        {!playing && (
                                            <PlayArrowIcon/>
                                        )}
                                        {playing && (
                                            <PauseIcon/>
                                        )}
                                    </IconButton>
                                    <IconButton aria-label="next" size="large" color="inherit"
                                                onClick={handleNextMedia} disabled={currentTrack.playerInfo.index === (currentPlaylistMedia.length - 1) || isNextPending(currentTrack, currentPlaylistMedia)}>
                                        {theme.direction === 'rtl' ? <SkipPreviousIcon/> : <SkipNextIcon/>}
                                    </IconButton>
                                    <IconButton aria-label="loop" size="large" color="inherit"
                                                onClick={handleLooping}>
                                        {loop ? <RepeatOnIcon/> : <RepeatIcon/>}
                                    </IconButton>
                                </Stack>
                            </Grid>
                            <Grid item={true} xs={12} pl={{sm: 2}} pr={{sm: 2}}>
                                <Slider
                                    aria-label="time-indicator"
                                    size="large"
                                    value={playedSeconds}
                                    min={0}
                                    max={duration}
                                    onChange={(e, value) => handleSeekChange(e, value)}
                                    onChangeCommitted={(_, value) => handleSeekCommited(value)}
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                        height: 6,
                                        '& .MuiSlider-thumb': {
                                            width: 12,
                                            height: 12,
                                            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                            '&::before': {
                                                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                            },
                                            '&:hover, &.Mui-focusVisible': {
                                                boxShadow: `0px 0px 0px 8px ${
                                                    theme.palette.mode === 'dark'
                                                        ? 'rgb(255 255 255 / 16%)'
                                                        : 'rgb(0 0 0 / 16%)'
                                                }`,
                                            },
                                            '&.Mui-active': {
                                                width: 20,
                                                height: 20,
                                            },
                                        },
                                        '& .MuiSlider-rail': {
                                            opacity: 0.28,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} pl={{sm: 2}} pr={{sm: 2}}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={0.5}
                                >
                                    <TinyText>
                                        <Duration seconds={playedSeconds}/>
                                    </TinyText>
                                    <Button component={Link} to={mediaPageUrl} style={{textDecoration: 'none', color: 'inherit', padding: '0', width: '100%'}}>
                                        <ScrollingText text={currentTrack.name} text2={currentTrack.artist ? (' - ' + currentTrack.artist) : ''}/>
                                    </Button>
                                    <TinyText>
                                        <Duration seconds={duration}/>
                                    </TinyText>
                                </Stack>
                            </Grid>
                        </Grid>
                        {!isIOS && (
                            <Grid item={true} xs={12} sm={2} pl={{sm: 2}}>
                                <Grid item={true} xs={12}>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={1.5}
                                    >
                                        <IconButton aria-label="play/pause" size="large" color="inherit"
                                                    onClick={handleToggleMute}>
                                            {!muted && volume === 0.0 && (
                                                <VolumeMute/>
                                            )}
                                            {!muted && volume < 0.5 && volume !== 0.0 && (
                                                <VolumeDown/>
                                            )}
                                            {!muted && volume >= 0.5 && (
                                                <VolumeUp/>
                                            )}
                                            {muted && (
                                                <VolumeOff/>
                                            )}
                                        </IconButton>
                                    </Stack>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Slider
                                        aria-label="volume-indicator"
                                        size="large"
                                        value={volume * 100}
                                        min={0}
                                        max={100}
                                        onChange={(e, value) => handleVolumeChange(e, value)}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                            height: 6,
                                            '& .MuiSlider-thumb': {
                                                width: 12,
                                                height: 12,
                                                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                                '&::before': {
                                                    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible': {
                                                    boxShadow: `0px 0px 0px 8px ${
                                                        theme.palette.mode === 'dark'
                                                            ? 'rgb(255 255 255 / 16%)'
                                                            : 'rgb(0 0 0 / 16%)'
                                                    }`,
                                                },
                                                '&.Mui-active': {
                                                    width: 20,
                                                    height: 20,
                                                },
                                            },
                                            '& .MuiSlider-rail': {
                                                opacity: 0.28,
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Card>

        );
    }
}

export function buildPlayerInfo(media, index, handleListItemClick) {
    let mediaTimestamp = null;
    if (media.duration >= 600) {
        mediaTimestamp = media.resumeTimestamp === null ? 0 : media.resumeTimestamp / media.duration;
    }
    const resumeTimestamp = mediaTimestamp === null ? 0 : mediaTimestamp;
    return {
        url: process.env.REACT_APP_BASE_URL + '/files/' + media.id,
        resumeTimestamp: resumeTimestamp,
        index: index,
        playlistContext: {programmaticClick: handleListItemClick}
    }
}
