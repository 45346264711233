import * as React from 'react';
import Box from '@mui/material/Box';
import {styled} from "@mui/material/styles";
import MediaControl from "./MediaControl"

const Offset = styled('div')(({theme}) => theme.mixins.toolbar);

const BottomBar = () => {
    return (
        <React.Fragment>
            <Box sx={{position: 'fixed', bottom: 0, left: 0, right: 0}}>
                <MediaControl/>
            </Box>
            <Offset/>
        </React.Fragment>
    );
}

export default BottomBar;