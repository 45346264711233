import {ApiClient} from "./ApiClient";

export function getAccount(uuid) {
    return ApiClient.get('/account/' + uuid);
}

export function getFollowers() {
    return ApiClient.post('/account/followers');
}

export function toggleFollowing(followerId) {
    return ApiClient.post('/account/follow/' + followerId);
}

export function isFollowing(followerId) {
    return ApiClient.post('/account/following/' + followerId);
}

export function getPublicId(config) {
    return ApiClient.get('/account/id');
}

export function createAccount() {
    return ApiClient.post('/account/id');
}

export function updateAccount(body) {
    return ApiClient.put('/account', body);
}
