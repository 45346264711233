function base64StringToFile (base64String, filename) {
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while(n--){
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, {type:mime})
}

export default function imageResize(imageFile, maxWidth, maxHeight, resultImageFileCallback) {
    if (imageFile) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = document.createElement("img");
            img.onload = function (event) {
                let width = img.width;
                let height = img.height;
                if (width > height) {
                    if (width > maxWidth) {
                        height = height * (maxWidth / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = width * (maxHeight / height);
                        height = maxHeight;
                    }
                }

                // Dynamically create a canvas element
                const canvas = document.createElement("canvas");
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                // Actual resizing
                ctx.drawImage(img, 0, 0, width, height);

                // Show resized image in preview element
                const imageName = imageFile.name.replace(/\.[^.]*$/, "");
                const resultFile = base64StringToFile(canvas.toDataURL('image/jpeg'), imageName + '.jpeg');
                resultImageFileCallback(resultFile);
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(imageFile);
    }
}


