import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ConfirmationModal({open, setOpen, actionTitle, actionMessage, acceptCallback}) {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {actionTitle}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {actionMessage}
                    <Stack component="span" justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" onClick={handleClose}>No</Button>
                        <Button variant="contained" onClick={acceptCallback}>Yes</Button>
                    </Stack>
                </Typography>
            </Box>
        </Modal>
    );
}