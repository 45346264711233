import * as React from 'react';
import Grid from '@mui/material/Grid';
import {Avatar, CardActionArea, Container, Skeleton, Tooltip} from "@mui/material";
import Typography from '@mui/material/Typography';
import {ApplicationGlobals} from "./App";
import * as PageConstants from "./PageConstants";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import AlertMessage from "./AlertMessage";
import AutoSwipeableView from "./AutoSwipeableView";
import SelectPlaylistModal from "./SelectPlaylistModal";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from "@mui/material/Stack";
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import GroupIcon from '@mui/icons-material/Group';
import ShareIcon from '@mui/icons-material/Share';
import {getAccount, getFollowers, updateAccount} from "./api/AccountService";
import TinyText from "./TinyText";
import {ShuraIconFull} from "./ShuraIcons";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {getRecentlyListened} from "./api/PlaylistService";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import TextField from "@mui/material/TextField";
import imageResize from "./ImageResize";
import {uploadImage} from "./api/MediaService";
import {TouchApp} from "@mui/icons-material";
import {LogtoGlobals} from "./index";

export default function LandingPage() {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    let isRendered = React.useRef(false);
    React.useEffect(() => {
        isRendered.current = true;
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.LANDING);
        return () => {
            isRendered.current = false;
        };
    }, []);

    const currentTrack = applicationGlobals.contexts.player.playerState.currentTrack;

    return (
        <Container style={{marginBottom: currentTrack ? "10em" : "0", marginTop: "1em"}}>
            <AccountCard/>
            <RecentlyListenedCard/>
            {/*<RecommendationsCard/>*/}
            <YouAreFollowingCard/>
            <NoLoginCard/>
        </Container>
    );
}

const NoLoginCard = () => {
    const logtoGlobals = React.useContext(LogtoGlobals);
    const applicationGlobals = React.useContext(ApplicationGlobals);
    let navigate = useNavigate();
    if (!applicationGlobals.contexts.auth.authState.loggedIn) {
        return (
            <React.Fragment>
                <Stack
                   direction="column"
                   justifyContent="center"
                   alignItems="center"
                   sx={{display: {xs: 'flex', md: 'none'}}}
                >
                    <ShuraIconFull sx={{width: {xs: '60%', sm: '35%', md: '25%'}, mt: "3em", mb: "6em"}}/>
                    <AutoSwipeableView sx={{ maxWidth: { sm: 600 }, flexGrow: 1}}/>
                    <Chip
                        color="error"
                        sx={{mt: '8em', fontSize: "1.2em", pt: "1em", pb: "1em", pl: "2.8em", pr: "2.8em", borderRadius: "50px", backgroundColor: "#F90B0B"}}
                        component={Button}
                        onClick={() => logtoGlobals.client.signIn(window.location.origin + '/callback')}
                        label="Login"
                        clickable/>
                </Stack>
                <Grid container
                    sx={{display: {xs: 'none', md: 'Flex'}, minHeight: '100vh'}}
                    columns={12}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={5}>
                        <Stack
                           direction="column"
                           justifyContent="center"
                           alignItems="center"
                           spacing={0.25}
                        >
                            <ShuraIconFull sx={{width: {xs: '60%'}, mb: '1em'}}/>
                            <Typography style={{marginBottom: '5em', textAlign: 'center'}}>
                                Privately organize playlists and backup your media in the cloud
                            </Typography>
                            <Chip
                                color="error"
                                sx={{fontSize: "1.2em", pt: "1em", pb: "1em", pl: "2.8em", pr: "2.8em", borderRadius: "50px", backgroundColor: "#F90B0B"}}
                                component={Button}
                                onClick={() => logtoGlobals.client.signIn(window.location.origin + '/callback')}
                                label="Login"
                                clickable/>
                        </Stack>
                    </Grid>
                    <Grid item xs={7}>
                        <Stack
                           direction="column"
                           justifyContent="center"
                           alignItems="flex-end"
                           spacing={0.25}
                        >
                            <AutoSwipeableView sx={{ maxWidth: { md: 550, lg: 600}, flexGrow: 1 }}/>
                        </Stack>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

const RecentlyListenedCard = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [recentlyListened, setRecentlyListened] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (applicationGlobals.contexts.auth.authState.loggedIn) {
            getRecentlyListened().then(response => {
                setRecentlyListened(response.data);
                setLoading(false);
            })
        }
    }, []);

    if (applicationGlobals.contexts.auth.authState.loggedIn && loading) {
        return (
            <React.Fragment>
                <Typography variant="h5" mt={1} mb={2} component="div"><Skeleton width={"180px"}/></Typography>
                <Grid container sx={{marginBottom: 2}} spacing={{xs: 1, md: 2}} columns={{xs: 3, md: 4}}>
                    {[0, 1, 2].map((_, index) => (
                        <Grid item xs={1} key={index}>
                            <Card>
                                <Skeleton sx={{ height: { xs: "140px", sm: "240px"}}} variant="rectangular" />
                                <CardContent>
                                    <Typography gutterBottom noWrap component="div">
                                        <Skeleton variant="rectangular"/>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        )
    }

    if (applicationGlobals.contexts.auth.authState.loggedIn && !loading && recentlyListened != null && recentlyListened.length > 0) {
        return (
            <React.Fragment>
                <Typography variant="h5" mt={1} mb={2} component="div">Recently Listened</Typography>
                <Grid container sx={{marginBottom: 2}} spacing={{xs: 1, md: 2}} columns={{xs: 3, md: 4}}>
                    {recentlyListened.map((playlist, index) => (
                        <Grid item xs={1} key={index}>
                            <Card>
                                <CardActionArea component={Link} to={"/playlist/" + playlist.id}>
                                    <CardMedia
                                        component="img"
                                        sx={{ height: {xs: "140px", sm: "240px"}}}
                                        image={process.env.REACT_APP_BASE_URL + '/files/image/' + playlist.coverImage}
                                        alt={playlist.name + ' playlist cover'}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom noWrap component="div">
                                            {playlist.name}
                                        </Typography>
                                        {playlist.ownerId != applicationGlobals.contexts.auth.authState.accountId && (
                                            <Tooltip title={`${playlist.ownerName}'s playlist`}>
                                                <GroupIcon sx={{filter:'drop-shadow(2px 2px 1px black)', position: 'absolute',top: '0.25em',right: '0.25em'}}/>
                                            </Tooltip>
                                        )}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        )
    }
}

const RecommendationsCard = () => {
    return (
        <React.Fragment>
            <Typography variant="h5" mt={1} mb={2} component="div">Recommendations</Typography>
            <Container sx={{marginBottom: 2}}>
                <Grid container justifyContent="left">
                    todo
                </Grid>
            </Container>
        </React.Fragment>
    )
}

const YouAreFollowingCard = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [following, setFollowing] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (applicationGlobals.contexts.auth.authState.loggedIn) {
            getFollowers().then(response => {
                setFollowing(response.data);
                setLoading(false);
            })
        }
    }, []);

    if (applicationGlobals.contexts.auth.authState.loggedIn && loading) {
        return (
            <React.Fragment>
                <Typography variant="h5" mt={1} mb={2} component="div"><Skeleton width={"150px"}/></Typography>
                <Grid container sx={{marginBottom: 2}} spacing={{xs: 1, md: 2}} columns={{xs: 3, sm: 6, md: 8}}>
                    {[0, 1, 2].map((_, index) => (
                        <Grid item xs={1} key={index}>
                            <Card>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    height={100}
                                >
                                    <Skeleton variant="circular" width={56} height={56}/>
                                </Box>
                                <CardContent>
                                    <TinyText noWrap textAlign="center" component="div">
                                        <Skeleton variant="text"/>
                                    </TinyText>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        )
    }

    if (applicationGlobals.contexts.auth.authState.loggedIn && !loading && following != null && following.length > 0) {
        return (
            <React.Fragment>
                <Typography variant="h5" mt={1} mb={2} component="div">Following</Typography>
                <Grid container sx={{marginBottom: 2}} spacing={{xs: 1, md: 2}} columns={{xs: 3, sm: 6, md: 8}}>
                    {following.map((followee, index) => (
                        <Grid item xs={1} key={index}>
                            <Card>
                                <CardActionArea component={Link} to={"/account/" + followee.followerId}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        height={100}
                                    >
                                        <Avatar sx={{width: 56, height: 56}}
                                                alt={`${followee.name}`}
                                                src={`${followee.avatar}`}
                                                variant={'circular'}>
                                        </Avatar>
                                    </Box>
                                    <CardContent>
                                        <TinyText noWrap textAlign="center" component="div">
                                            {followee.name}
                                        </TinyText>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment>
        )
    }
}

const AccountCard = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    let navigate = useNavigate();
    const [account, setAccount] = React.useState(null);
    const [avatar, setAvatar] = React.useState(null);
    const [avatarImageFile, setAvatarImageFile] = React.useState( null);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [selectPlaylistOpen, setSelectPlaylistOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [accountName, setAccountName] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (applicationGlobals.contexts.auth.authState.loggedIn) {
            getAccount(applicationGlobals.contexts.auth.authState?.accountId).then(response => {
                setAccount(response.data);
                setAccountName(response.data.name);
                setAvatar(response.data.avatar);
                setLoading(false);
            })
        }
        if (avatarImageFile) {
            // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
            return () => {
                URL.revokeObjectURL(avatarImageFile);
                setAvatarImageFile(null);
            }
        }
    }, []);

    const handleShareClick = (event, account) => {
        const accountUri = window.location.href + 'account/' + account.id;
        if (navigator.share) {
            navigator.share({
                title: 'Share ' + account.name,
                url: accountUri
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            navigator.clipboard.writeText(accountUri)
                .then(() => {
                    setAlertOpen(true);
                    setAlertMessage("Account link has been copied!");
                }).catch(console.error);
        }
    }

    const goToCreatePlaylist = () => {
        return navigate({pathname: '/create/playlist'}, {replace: false});
    };

    const goToAllMedia = () => {
        return navigate({pathname: '/account/' + account.id + '/media'}, {replace: false});
    };

    const handleAddMediaClick = () => {
        setSelectPlaylistOpen(true);
    }

    function handleEnterEditState() {
        setEditing(true);
    }

    function handleCancelEdit() {
        setEditing(false);
        setAvatarImageFile(null);
    }

    function handleAccountInputName(event) {
        setAccountName(event.target.value);
    }

    function handleInputCover(event) {
        imageResize(event.target.files[0], 460, 460, (resizedImageFile) => {
            setAvatarImageFile(resizedImageFile);
        });
    }

    async function handleSaveEditChanges() {
        let imageUrl = null;
        if (avatarImageFile != null) {
            const imageData = new FormData();
            imageData.append('coverImage', avatarImageFile);

            const imageUploadResponse = await uploadImage(imageData).catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(error.message);
                }
            });

            imageUrl = process.env.REACT_APP_BASE_URL + '/files/image/' + imageUploadResponse.data;
        }

        const requestBody = {
            name: accountName?.trim(),
            avatar: imageUrl
        }
        updateAccount(requestBody).then(response => {
            account.name = response.data.name;
            account.avatar = response.data.avatar;
            setAccount(account);
            setEditing(false);
            const authentication = {
                loggedIn: true,
                name: account.name,
                avatar: account.avatar,
                accountId: applicationGlobals.contexts.auth.authState.accountId,
            };
            applicationGlobals.contexts.auth.setAuthState(authentication);
        })
    }

    if (applicationGlobals.contexts.auth.authState.loggedIn && loading) {
        return (
            <React.Fragment>
                <Typography variant="h5" mt={1} mb={2} component="div">
                    <Stack direction="row" style={{paddingBottom: '0.5em'}}>
                        <Skeleton width={"140px"} variant={"text"}/>
                        <Skeleton width={"20px"} style={{marginLeft: '1em'}}/>
                    </Stack>
                </Typography>
                <Grid container sx={{marginBottom: 2}} spacing={{xs: 1, md: 2}} columns={{xs: 3, sm: 6, md: 8}}>
                    <Grid item xs={1}>
                        <Card>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: {xs: "100px"}}}
                            >
                                <Skeleton variant="circular" width={"60px"} height={"60px"}/>
                            </Box>
                            <CardContent>
                                <TinyText component="div" textAlign="center">
                                    <Skeleton variant="text"/>
                                </TinyText>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Stack
                            direction="column"
                            justifyContent="start"
                            alignItems="start"
                            spacing={2}
                            sx={{ height: {xs: "100px"}}}
                        >
                            <Skeleton variant="text" width={"120px"}/>
                            <Skeleton variant="text" width={"120px"}/>
                            <Skeleton variant="text" width={"120px"}/>
                            <Skeleton variant="text" width={"120px"}/>
                        </Stack>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    if (applicationGlobals.contexts.auth.authState.loggedIn && !loading && account) {
        return (
        <React.Fragment>
            <Typography variant="h5" mt={1} mb={2} component="div">
                {!editing && (
                    <Stack direction="row" style={{paddingBottom: '0.5em'}}>
                        <span>{account.name}</span>
                        <Tooltip title="Edit Profile" sx={{marginLeft: 1}}>
                            <IconButton size="small" color="inherit" onClick={handleEnterEditState}>
                                <SettingsIcon sx={{color: 'gray'}}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
                {editing && (
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <TextField id="outlined-basic" label="Profile Name" variant="outlined" value={accountName}
                                   onInput={handleAccountInputName}/>
                        <Tooltip title="Cancel Changes" sx={{marginLeft: 1}}>
                            <IconButton size="small" color="inherit" onClick={handleCancelEdit}>
                                <CloseIcon sx={{color: 'red'}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Save Changes" sx={{marginLeft: 1}}>
                            <IconButton size="small" color="inherit" onClick={handleSaveEditChanges}>
                                <CheckIcon sx={{color: 'green'}}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
            </Typography>
            <Grid container sx={{marginBottom: 2}} spacing={{xs: 1, md: 2}} columns={{xs: 3, sm: 6, md: 8}}>
                <Grid item xs={1}>
                    <Card>
                        <CardActionArea component={!editing ? Link : 'div'} to={"/account/" + account.id}>
                            {editing && (
                                <React.Fragment>
                                    <input
                                        hidden
                                        accept=".jpeg,.jpg,.png,.tiff"
                                        id="user-avatar"
                                        onChange={handleInputCover}
                                        multiple
                                        type="file"
                                    />
                                    <label htmlFor="user-avatar">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ height: {xs: "100px"}}}
                                        >
                                        {avatarImageFile && (
                                            <Avatar sx={{width: 60, height: 60}}
                                                    alt={account.name}
                                                    src={URL.createObjectURL(avatarImageFile)}
                                                    variant={'circular'}>
                                            </Avatar>
                                        )}
                                        {!avatarImageFile && (
                                            <Avatar sx={{width: 60, height: 60}}
                                                    alt={account.name}
                                                    src={account.avatar}
                                                    variant={'circular'}>
                                            </Avatar>
                                        )}
                                        </Box>
                                        <Box sx={{p:1, display:'flex', justifyContent:'center'}}>
                                            <TinyText><TouchApp></TouchApp></TinyText>
                                        </Box>
                                    </label>
                                </React.Fragment>
                            )}
                            {!editing && (
                                <React.Fragment>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        sx={{ height: {xs: "100px"}}}
                                    >
                                        <Avatar sx={{width: 60, height: 60}}
                                                alt={account.name}
                                                src={account.avatar}
                                                variant={'circular'}>
                                        </Avatar>
                                    </Box>
                                    <CardContent>
                                        <TinyText component="div" textAlign="center">
                                            Account
                                        </TinyText>
                                    </CardContent>
                                </React.Fragment>
                            )}
                        </CardActionArea>
                    </Card>
                </Grid>
                {!editing && (
                    <Grid item xs={2}>
                        <Stack
                           direction="column"
                           justifyContent="start"
                           alignItems="start"
                           spacing={0.8}
                           sx={{ height: {xs: "100px"}}}
                       >
                            <Button sx={{justifyContent: "inherit"}} size="small" onClick={goToCreatePlaylist}>
                                <PlaylistAddIcon/>
                                <span style={{paddingLeft: '1em'}}>Create Playlist</span>
                            </Button>
                            <Button sx={{justifyContent: "inherit"}} size="small" onClick={() => {
                                handleAddMediaClick()
                            }}>
                                <AddIcon/>
                                <span style={{paddingLeft: '1em'}}>Add Media</span>
                            </Button>
                            <Button sx={{justifyContent: "inherit"}} size="small" onClick={goToAllMedia}>
                                <VideoLibraryIcon/>
                                <span style={{paddingLeft: '1em'}}>All Media</span>
                            </Button>
                            <Button sx={{justifyContent: "inherit"}} size="small"
                                    onClick={(event) => handleShareClick(event, account)}>
                                <ShareIcon/>
                                <span style={{paddingLeft: '1em'}}>Share</span>
                            </Button>
                        </Stack>
                    </Grid>
                )}
           </Grid>
           <SelectPlaylistModal open={selectPlaylistOpen} setOpen={setSelectPlaylistOpen} account={account} />
           <AlertMessage open={alertOpen} setOpen={setAlertOpen} message={alertMessage} time={3000}/>
       </React.Fragment>
        );
    }
}
