import {ApiClient} from "./ApiClient";

export function getPlaylist(uuid) {
    return ApiClient.get('/playlist/' + uuid);
}

export function addPlaylist(playlist, config) {
    return ApiClient.postForm('/playlist', playlist, config);
}

export function patchPlaylist(playlistId, playlist) {
    return ApiClient.patchForm('/playlist/' + playlistId, playlist);
}

export function patchLastMediaPlaylist(playlistId, mediaId) {
    return ApiClient.patch('/playlist/' + playlistId + '/last-media/' + mediaId);
}

export function patchMediasPlaylist(playlistId, medias) {
    return ApiClient.patch('/playlist/' + playlistId + '/medias', medias);
}

export function addMediaToPlaylist(playlistId, mediaId) {
    return ApiClient.post('/playlist/' + playlistId + '/media/' + mediaId);
}

export function addMediaCopyToPlaylist(playlistId, mediaId) {
    return ApiClient.post('/playlist/' + playlistId + '/media/' + mediaId + '/copy');
}

export function addPlaylistToFavorites(foreignPlaylistId) {
    return ApiClient.post('/playlist/' + foreignPlaylistId + '/favorite');
}

export function addSourceMediaToPlaylist(playlistId, mediaSource) {
    return ApiClient.post('/playlist/' + playlistId + '/media/source', mediaSource);
}

export function deletePlaylist(playlistId) {
    return ApiClient.delete('/playlist/' + playlistId);
}

export function deleteMediaFromPlaylist(playlistId, mediaId) {
    return ApiClient.delete('/playlist/' + playlistId + '/media/' + mediaId);
}

export function getRecentlyListened() {
    return ApiClient.post('/playlist/recent');
}

export function addRecentlyListened(playlistId) {
    ApiClient.post('/playlist/' + playlistId + '/recent');
}

export function getRecentlyPlaylist() {
    return ApiClient.post('/playlist/last-played');
}
