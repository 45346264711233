import * as React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {ApplicationGlobals} from "./App";
import {ArrowBackIosNew, Login, Logout} from "@mui/icons-material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import * as PageContestants from "./PageConstants";
import {Avatar, Drawer, ListItem, ListItemAvatar, ListItemIcon, Tooltip} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CreatePlaylist from "./CreatePlaylist";
import {ShuraIcon} from "./ShuraIcons";
import {LogtoGlobals} from "./index";
import ResumeBar from "./ResumeBar";

const Offset = styled('div')(({theme}) => theme.mixins.toolbar);

const TopBar = ({resumeMedia}) => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const landingAndNotLoggedIn = (applicationGlobals.contexts.page.currentPage === PageContestants.LANDING) && !applicationGlobals.contexts.auth.authState.loggedIn;
    if (!landingAndNotLoggedIn) {
        return (
            <React.Fragment>
                <Box sx={{flexGrow: 1, marginBottom: '1em'}}>
                    <AppBar position="fixed">
                        <Toolbar>
                            <Container disableGutters>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={0.5}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="left"
                                        alignItems="center"
                                        spacing={0.5}
                                    >
                                        <BackButton/>
                                        <ShuraLogo/>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        justifyContent="right"
                                        alignItems="center"
                                        spacing={0.5}
                                    >
                                        <AddMediaToPlaylist/>
                                        <CreatePlaylist/>
                                        <Profile/>
                                    </Stack>
                                </Stack>
                            </Container>
                        </Toolbar>
                        <ResumeBar resumeMedia={resumeMedia}/>
                    </AppBar>
                </Box>
                <Offset/>
            </React.Fragment>
        );
    }
}

const AddMediaToPlaylist = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const render = (applicationGlobals.contexts.page.currentPage === PageContestants.PLAYLIST) && applicationGlobals.contexts.auth.authState.loggedIn;
    const playlistOwned = applicationGlobals.contexts.playlist.playlistState.visited?.ownerId === applicationGlobals.contexts.auth.authState.accountId;
    let navigate = useNavigate();
    const urlLocation = useLocation();

    function getRegistrationTokenFromLocation(pathName) {
        return pathName.slice(pathName.indexOf('/', 1) + 1, pathName.length);
    }

    const addMedia = function () {
        return navigate({pathname: "/create/media/playlist/" + getRegistrationTokenFromLocation(urlLocation.pathname)}, {replace: false});
    }

    if (render && playlistOwned) {
        return (
            <Tooltip title="Add media to this playlist">
                <IconButton size="large" color="inherit" onClick={() => addMedia()}>
                    <AddIcon/>
                </IconButton>
            </Tooltip>
        );
    }
}

const Profile = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    let navigate = useNavigate();
    const logtoGlobals = React.useContext(LogtoGlobals);
    const [state, setState] = React.useState({
        right: false
    });
    const anchor = "right";

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };

    const logout = function () {
        logtoGlobals.client.signOut(window.location.origin);
    }

    const accountUri = "/account/" + applicationGlobals.contexts.auth.authState.accountId;
    var loggedIn = applicationGlobals.contexts.auth.authState.loggedIn;
    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {!loggedIn && (
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => logtoGlobals.client.signIn(window.location.origin + '/callback')}>
                            <ListItemIcon>
                                <Login/>
                            </ListItemIcon>
                            <ListItemText primary={"Login"}/>
                        </ListItemButton>
                    </ListItem>
                )}
                {loggedIn && (
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={accountUri}>
                            <ListItemAvatar>
                                <Avatar sx={{width: 24, height: 24}}
                                        alt={applicationGlobals.contexts.auth.authState.name}
                                        src={applicationGlobals.contexts.auth.authState.avatar}/>
                            </ListItemAvatar>
                            <ListItemText primary={applicationGlobals.contexts.auth.authState.name}/>
                        </ListItemButton>
                    </ListItem>
                )}
                {loggedIn && (
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => logout()}>
                            <ListItemIcon>
                                <Logout/>
                            </ListItemIcon>
                            <ListItemText primary={"Logout"}/>
                        </ListItemButton>
                    </ListItem>
                )}
            </List>
        </Box>
    );

    return (
        <React.Fragment>
            <Tooltip title="Profile">
                <IconButton size="large" color="inherit" onClick={toggleDrawer(anchor, true)}>
                    <Avatar sx={{width: 24, height: 24}}
                            alt={applicationGlobals.contexts.auth.authState.name}
                            src={applicationGlobals.contexts.auth.authState.avatar}
                            variant={'circle'}>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
            >
                {list(anchor)}
            </Drawer>
        </React.Fragment>
    );
}

const ShuraLogo = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const onLandingPage = (applicationGlobals.contexts.page.currentPage === PageContestants.LANDING)
    return (
        <Tooltip title={"Landing Page"}>
            <IconButton size="large" color="inherit" style={{marginLeft: onLandingPage ? 0 : "0.38em", textTransform: "none"}} component={Link} to={"/"}>
                <ShuraIcon/>
            </IconButton>
        </Tooltip>
    );
}

const BackButton = () => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    let navigate = useNavigate();
    const urlLocation = useLocation();

    function getNavigationLabel() {
        if (applicationGlobals.contexts.page.currentPage === PageContestants.PLAYLIST) {
            return "Back to Account";
        } else if (applicationGlobals.contexts.page.currentPage === PageContestants.ACCOUNT) {
            return "Back To Landing";
        } else {
            return ""
        }
    }

    function goto() {
        if (applicationGlobals.contexts.page.currentPage === PageContestants.PLAYLIST) {
            const authenticationState = applicationGlobals.contexts.auth.authState;
            const accountId = authenticationState?.accountId;
            const loggedIn = authenticationState.loggedIn;
            const ownerId = applicationGlobals.contexts.playlist.playlistState.visited?.ownerId;
            if (accountId !== ownerId) {
                const accountUri = "/account/" + ownerId;
                return navigate({pathname: accountUri}, {replace: false});
            }
            if (loggedIn && accountId === ownerId) {
                const accountUri = "/account/" + accountId;
                return navigate({pathname: accountUri}, {replace: false});
            } else {
                return navigate({pathname: "/"}, {replace: false});
            }
        }
        if (applicationGlobals.contexts.page.currentPage === PageContestants.ACCOUNT) {
            return navigate({pathname: "/"}, {replace: false});
        }
        if (applicationGlobals.contexts.page.currentPage === PageContestants.CREATE_PLAYLIST) {
            return navigate({pathname: "/"}, {replace: false});
        }
        if (applicationGlobals.contexts.page.currentPage === PageContestants.CREATE_MEDIA) {
            function getPlaylistGuid(pathName) {
                return pathName.slice(pathName.indexOf('playlist/') + 9, pathName.length);
            }
            return navigate({pathname: "/playlist/" + getPlaylistGuid(urlLocation.pathname)}, {replace: false});
        }
        if (applicationGlobals.contexts.page.currentPage === PageContestants.ALL_MEDIA) {
            return navigate({pathname: "/"}, {replace: false});
        }
        if (applicationGlobals.contexts.page.currentPage === PageContestants.MEDIA) {
            return navigate(-1);
        }
        return false;
    }

    // show only if not on the root page
    const showButton = !(applicationGlobals.contexts.page.currentPage === PageContestants.LANDING) && window.history.length > 1;
    if (showButton) {
        return (
            <Tooltip title={getNavigationLabel()}>
                <IconButton size="large" color="inherit" onClick={() => goto()}>
                    <ArrowBackIosNew/>
                </IconButton>
            </Tooltip>
        );
    }
}

export default TopBar;
