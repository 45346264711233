import {ApiClient} from "./ApiClient";

export function getMedias() {
    return ApiClient.get('/media');
}

export function getMedia(uuid) {
    return ApiClient.get('/media/' + uuid);
}

export function playMedia(uuid) {
    return ApiClient.get('/files/' + uuid);
}

export function uploadImage(image) {
    return ApiClient.postForm('/files/image', image);
}

export function initUploadMedia(media) {
    return ApiClient.postForm('/files', media);
}

export function chunkUploadMedia(mediaChunk) {
    return ApiClient.postForm('/files/chunkload', mediaChunk);
}

export function patchMedia(id, media) {
    return ApiClient.patchForm('/media/' + id, media);
}

export function updateResumeTimestamp(id, timestamp) {
    return ApiClient.patch('/media/' + id + '/timestamp/' + timestamp);
}
