import localStorage from 'local-storage';

const themeSettingsKey = 'settings/theme';

export function setLocalThemeSettings(settings) {
    localStorage.set(themeSettingsKey, settings);
}

export function getLocalThemeSettings() {
    return localStorage.get(themeSettingsKey);
}

export function clearLocalSettings() {
    localStorage.remove(themeSettingsKey);
}

export function getLocalVolume() {
    return localStorage.get("volume");
}

export function setLocalVolume(volume) {
    localStorage.set("volume", volume);
}
