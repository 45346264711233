import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {ApplicationGlobals} from "./App";
import * as PageConstants from "./PageConstants";
import {chunkUploadMedia, initUploadMedia, patchMedia} from "./api/MediaService"
import {addMediaToPlaylist, addSourceMediaToPlaylist} from "./api/PlaylistService";
import Box from "@mui/material/Box";
import {MediaDropZone} from "./UploadDropZone";
import {Queue} from "./Queue";
import EditableMedia from "./EditableMediaList";
import PropTypes from 'prop-types';
import {Tab, Tabs} from "@mui/material";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{pt: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const defaultHelperText = "Add a url to any media source it will be added to your playlist!";

export default function CreateMediaPage() {
    let navigate = useNavigate();
    const {playlistId} = useParams();
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [uploading, setUploading] = React.useState(false);
    const [mediaUrl, setMediaUrl] = React.useState(null);
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(defaultHelperText);
    const [uploadType, setUploadType] = React.useState(0);
    const [mediaList, setMediaList] = React.useState([]);
    const [mediaListUploadPercent, setMediaListUploadPercent] = React.useState({});
    const [mediaListUploaded, setMediaListUploaded] = React.useState({});

    React.useEffect(() => {
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.CREATE_MEDIA);
    }, []);

    const handleCreateTypeToggle = (event, newUploadType) => {
        setUploadType(newUploadType);
    };

    function handleInputUrl(event) {
        setMediaUrl(event.target.value.trim());
    }

    function handleSubmitMediaUrl(event) {
        event.preventDefault();
        setUploading(true);
        setHelperText(defaultHelperText);
        setError(false);
        addSourceMediaToPlaylist(playlistId, {
            uri: mediaUrl
        }).then(response => {
            return navigate({pathname: "/playlist/" + playlistId}, {replace: false});
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                console.log(error.message);
            }
            var defaultErrorMessage = 'Service unavailable';
            if (error.response.status === 401) {
                defaultErrorMessage += ': please login again'
            }
            setHelperText(defaultErrorMessage);
            setError(true);
        }).finally(() => {
            setUploading(false);
        });
    }

    function chunkArray(array, chunkSize) {
        return Array.from(
            {length: Math.ceil(array.length / chunkSize)},
            (_, index) => array.slice(index * chunkSize, (index + 1) * chunkSize)
        );
    }

    function progressFileUpload(mediaListFile, chunk, chunks) {
        const percent = Math.ceil(((chunk + 1) / chunks) * 100);
        mediaListFile.uploadPercent = percent;
        mediaListUploadPercent[mediaListFile.id] = percent;
        const mediaListUploadPercentShallowCopy = {...mediaListUploadPercent};
        setMediaListUploadPercent(mediaListUploadPercentShallowCopy);
    }

    async function processFileChunks(uuid, mediaListFile) {
        const file = mediaListFile.data;
        const bytes = (1024 * 1024) * 4; // 4 mebibyte chunks
        const chunks = Math.ceil(file.size / bytes);
        for (let chunk = 0; chunk < chunks; chunk++) {
            let startByte = (chunk) * bytes;
            let endByte = Math.min(((chunk + 1) * bytes), file.size);
            let fileChunk = file.slice(startByte, endByte);
            const data = new FormData();
            data.append('guid', uuid);
            data.append('file', fileChunk);
            data.append('chunk', chunk);
            data.append('chunks', chunks);

            await chunkUploadMedia(data);

            progressFileUpload(mediaListFile, chunk, chunks);
        }
    }

    async function processUploadQueue(queue, mediaListFile) {
        const data = new FormData();
        data.append('name', mediaListFile.name.trim());
        data.append('type', mediaListFile.data.name.trim().split('.').pop());
        if (mediaListFile.artist) {
            data.append('artist', mediaListFile.artist.trim());
        }
        if (mediaListFile.album) {
            data.append('album', mediaListFile.album.trim());
        }

        const response = await initUploadMedia(data).catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                console.log(error.message);
            }
            setHelperText('Service unavailable');
            setUploading(false);
            setError(true);
        });

        const mediaCreatedUUID = response.data.id;
        await processFileChunks(mediaCreatedUUID, mediaListFile);
        if (mediaListFile.coverImage) {
            const coverImageData = new FormData();
            coverImageData.append('coverImage', mediaListFile.coverImage);
            await patchMedia(mediaCreatedUUID, coverImageData);
        }

        if (playlistId) {
            const addedToPlaylistResponse = await addMediaToPlaylist(playlistId, response.data.id);
            console.log(addedToPlaylistResponse.data);
            mediaListUploaded[mediaListFile.id] = true;
            const mediaListUploadedShallowClone = {...mediaListUploaded};
            let uploading = false;
            for (let i = 0; i < mediaList.length; i++) {
                const id = mediaList[i].id;
                if (!!mediaListUploadedShallowClone[id] === false) {
                    uploading = true;
                    break;
                }
            }
            setUploading(uploading);
            setMediaListUploaded(mediaListUploadedShallowClone);
            if (!queue.isEmpty) {
                const nextMedia = queue.dequeue();
                await processUploadQueue(queue, nextMedia);
            }
        }
    }

    async function uploadMediaListBucket(mediaListFileBucket) {
        const queue = new Queue();
        mediaListFileBucket.map(mediaListFile => {
            if (!!mediaListUploaded[mediaListFile.id] === false) {
                queue.enqueue(mediaListFile);
            }
        })
        if (!queue.isEmpty) {
            await processUploadQueue(queue, queue.dequeue());
        }
    }

    async function uploadData(mediaListFiles) {
        try {
            const mediaListFileBuckets = chunkArray(mediaListFiles, 1);
            for (const mediaListFileBucket of mediaListFileBuckets) {
                await uploadMediaListBucket(mediaListFileBucket);
            }
            return navigate({pathname: "/playlist/" + playlistId}, {replace: false});
        } catch (error) {
            setUploading(false);
            console.log(error.message);
        }
    }

    function handleSubmitUpload(event) {
        event.preventDefault();
        setUploading(true);

        uploadData(mediaList);
    }

    const onDrop = React.useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
            }
            reader.readAsArrayBuffer(file)
        });
        const addedMediaList = acceptedFiles.map((af, index) => {
            const randomId = Math.random().toString(36).slice(2, 4);
            return {
                id: index + randomId,
                draggableId: 'id_' + (index + randomId),
                data: af,
                name: af.name.replace(/\.[^.]*$/, "")
            }
        });
        const allMediaList = mediaList.concat(addedMediaList);
        setMediaList(allMediaList);
    }, [mediaList]);

    function a11yProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    return (
        <Box pb={{sm: '5em', xs: '10em'}}>
            <Container style={{marginBottom: "1em", marginTop: "1em"}}>
                <Typography variant="h5" mt={1} mb={2} component="div">Add Media</Typography>
                <Tabs value={uploadType} onChange={handleCreateTypeToggle} aria-label="add media tabs">
                    <Tab label="Source" {...a11yProps(0)}/>
                    <Tab label="Upload" {...a11yProps(1)}/>
                    {/*<Tab label="Existing" {...a11yProps(2)}/>*/}
                </Tabs>

                <TabPanel index={uploadType} value={0}>
                    <FormControl error={error} variant="standard">
                        <Grid component="form" container spacing={2} onSubmit={handleSubmitMediaUrl}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="left">
                                    <TextField id="outlined-basic" required onInput={handleInputUrl}
                                               type="text"
                                               label="URL"
                                               variant="outlined"/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent={"left"}>
                                    <FormHelperText>{helperText}</FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="left">
                                    <Button variant="contained" type="submit"
                                            disabled={!mediaUrl || uploading}>Add</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormControl>
                </TabPanel>
                <TabPanel index={uploadType} value={1}>
                    <MediaDropZone onDrop={onDrop}/>
                    <Grid item xs={12} sx={{paddingBottom: 2, paddingTop: 2}}>
                        <Grid container>
                            <EditableMedia
                                mediaList={mediaList}
                                setMediaList={setMediaList}
                                mediaListUploaded={mediaListUploaded}
                                mediaListUploadPercent={mediaListUploadPercent}
                                uploading={uploading}
                                removable={true}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="left">
                            <Button
                                onClick={handleSubmitUpload}
                                disabled={(mediaList.length === 0) || uploading}
                                variant="contained" type="submit">
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                {/*<TabPanel index={uploadType} value={2}>*/}
                {/*    <MediaList/>*/}
                {/*</TabPanel>*/}
            </Container>
        </Box>
    );
}
