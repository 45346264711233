import * as React from 'react';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate} from "react-router-dom";
import {ApplicationGlobals} from "./App";
import * as PageContestants from "./PageConstants";

export default function CreatePlaylist() {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    let navigate = useNavigate();

    const goToCreatePlaylist = () => {
        return navigate({pathname: '/create/playlist'}, {replace: false});
    };

    const render = (applicationGlobals.contexts.page.currentPage !== PageContestants.CREATE_MEDIA) && applicationGlobals.contexts.auth.authState.loggedIn;
    if (render) {
        return (
            <Tooltip title="Add Playlist">
                <IconButton size="large" color="inherit" onClick={goToCreatePlaylist}>
                    <PlaylistAddIcon/>
                </IconButton>
            </Tooltip>
        );
    }
}
