import * as React from 'react';

import {createTheme, ThemeProvider} from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import {getLocalThemeSettings, setLocalThemeSettings} from "./SettingsStorage";
import TopBar from './TopBar'
import BottomBar from "./BottomBar";
import {BrowserRouter, Route, Routes, useNavigate,} from "react-router-dom";
import PlaylistPage from "./PlaylistPage";
import AccountPage from "./AccountPage";
import LandingPage from "./LandingPage";
import CreatePlaylistPage from "./CreatePlaylistPage";
import CreateMediaPage from "./CreateMediaPage";
import * as PageConstants from "./PageConstants";
import {createAccount, getPublicId} from "./api/AccountService";
import MediaList from "./MediaList";
import MediaPage from "./MediaPage";
import {AxiosInterceptor} from "./AxiosInterceptor";
import {LogtoGlobals} from "./index";
import {getRecentlyPlaylist} from "./api/PlaylistService";

const setAuthenticatedUser = (setLoading, setAuthState) => {
    return getPublicId().then(response => {
        const accountResponse = response.data;
        if (accountResponse) {
            const authentication = {
                loggedIn: true,
                name: accountResponse.name,
                avatar: accountResponse.avatar,
                accountId: accountResponse.id,
            };
            setAuthState(authentication);
            setLoading(false);
        } else {
            // account doesn't exist, dynamically create account for this user
            return createAccount().then(response => {
                const accountResponse = response.data;
                const authentication = {
                    loggedIn: true,
                    name: accountResponse.name,
                    avatar: accountResponse.avatar,
                    accountId: accountResponse.id,
                };
                setAuthState(authentication);
                setLoading(false);
            });
        }
    })
}

const Callback = () => {
    const [loading, setLoading] = React.useState(true);
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const logtoGlobals = React.useContext(LogtoGlobals);
    let navigate = useNavigate();

    React.useEffect(() => {
        async function processLogin() {
            try {
                await logtoGlobals.client.handleSignInCallback(window.location.href);
                await logtoGlobals.client.isAuthenticated();
                await logtoGlobals.client.fetchUserInfo().then(userInfoResponse => {
                    setAuthenticatedUser(setLoading, applicationGlobals.contexts.auth.setAuthState).then(() => {
                        return navigate({pathname: "/"}, {replace: false});
                    });
                })
            } catch(e) {
                console.log(e);
                await logtoGlobals.client.signIn(window.location.origin + '/callback');
            }
        }

        processLogin();

        return ()=> {};
    }, []);

    // When it's working in progress
    if (loading) {
        return <div>Redirecting...</div>;
    }
};

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

export const ApplicationGlobals = React.createContext(null);

export default function Application() {
    let themeSetting = getLocalThemeSettings();
    const logtoGlobals = React.useContext(LogtoGlobals);
    let currentThemeValue = 'dark';
    if (themeSetting !== null && themeSetting !== undefined) {
        // settings exist, get theme
        currentThemeValue = themeSetting;
    } else {
        themeSetting = currentThemeValue;
        setLocalThemeSettings(themeSetting);
    }
    const [mode, setMode] = React.useState(currentThemeValue);
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const theme = prevMode === 'light' ? 'dark' : 'light';
                    setLocalThemeSettings(theme);
                    return theme;
                });
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode],
    );

    const [pageNameState, setPageNameState] = React.useState(PageConstants.LANDING);
    const pageContext = {
        currentPage: pageNameState,
        setCurrentPage: setPageNameState
    }
    const [authState, setAuthState] = React.useState({
        loggedIn: false,
        name: null,
        avatar: null,
        accountId: null,
    });
    const authContext = {
        authState: authState,
        setAuthState: setAuthState
    }
    const [playerState, setPlayerState] = React.useState({
        currentTrack: null,
        currentPlaylist: null,
        currentPlaylistMedia: null,
    });
    const playerContext = {
        playerState: playerState,
        setPlayerState: setPlayerState
    }
    const [playlistState, setPlaylistState] = React.useState({
        visited: null
    });
    const playlistContext = {
        playlistState: playlistState,
        setPlaylistState: setPlaylistState,
    }
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [lastFocusedMediaPublicId, setLastFocusedMediaPublicId] = React.useState(null);
    const [resumeMedia, setResumeMedia] = React.useState({
        playlist: null,
        media: null
    });
    const resumeContext = {
        resumeMedia: resumeMedia,
        setResumeMedia: setResumeMedia
    }
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function checkLogin() {
            const loggedIn = await logtoGlobals.client.isAuthenticated();
            if (loggedIn) {
                setAuthenticatedUser(setLoading, setAuthState).then(()=> {
                    getRecentlyPlaylist().then(response => {
                        const playlist = response.data;
                        if (playlist) {
                            const mediaList = playlist.medias;
                            var index = 0;
                            if (mediaList) {
                                for (var i = 0; i < mediaList.length; i++) {
                                    if (mediaList[i].id == playlist.lastFocusedMediaPublicId) {
                                        index = i;
                                        break;
                                    }
                                }
                                const currentTrack = mediaList[index];
                                setResumeMedia({
                                    playlist: playlist,
                                    media: currentTrack
                                })
                            }
                        }
                    })
                });
                return;
            }
            setLoading(false);
        }
        checkLogin();
    }, []); // Or [] if effect doesn't need props or state

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <AxiosInterceptor>
                    <BrowserRouter>
                        <ApplicationGlobals.Provider
                            value={{
                                contexts: {
                                    theme: ColorModeContext,
                                    player: playerContext,
                                    playlist: playlistContext,
                                    page: pageContext,
                                    auth: authContext,
                                    resume: resumeContext
                                }
                            }}>
                            <TopBar resumeMedia={resumeMedia}/>
                            {!loading && (
                                <Routes>
                                    <Route path="/" element={<LandingPage/>}/>
                                    <Route path="/playlist/:id" element={<PlaylistPage selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} lastFocusedMediaPublicId={lastFocusedMediaPublicId} setLastFocusedMediaPublicId={setLastFocusedMediaPublicId}/>}/>
                                    <Route path="/media/:mid" element={<MediaPage selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setLastFocusedMediaPublicId={setLastFocusedMediaPublicId}/>}/>
                                    <Route path="/playlist/:pid/media/:mid" element={<MediaPage selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} setLastFocusedMediaPublicId={setLastFocusedMediaPublicId}/>}/>
                                    <Route path="/account/:id" element={<AccountPage/>}/>
                                    <Route path="/account/:id/media" element={<MediaList selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}/>}/>
                                    <Route path="/create/playlist" element={<CreatePlaylistPage/>}/>
                                    <Route path="/create/media" element={<CreateMediaPage/>}/>
                                    <Route path="/create/media/playlist/:playlistId" element={<CreateMediaPage/>}/>
                                    <Route path="/callback" element={<Callback />} />
                                </Routes>
                            )}
                            <BottomBar/>
                        </ApplicationGlobals.Provider>
                    </BrowserRouter>
                </AxiosInterceptor>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
