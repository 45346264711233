import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {ApplicationGlobals} from "./App";
import * as PageConstants from "./PageConstants";
import {addPlaylist} from "./api/PlaylistService"
import Box from "@mui/material/Box";
import CircularProgressWithLabel from "./CirclularProgressWithLabel";
import {ImageDropZone} from "./UploadDropZone";
import imageResize from "./ImageResize";

export default function CreatePlaylistPage() {
    let navigate = useNavigate();
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [uploading, setUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [name, setName] = React.useState();
    const [coverImage, setCoverImage] = React.useState();
    const [visible, setVisible] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(null);

    React.useEffect(() => {
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.CREATE_PLAYLIST);
        if (coverImage) {
            // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
            return () => URL.revokeObjectURL(coverImage.preview);
        }
    }, []);

    function handleInputName(event) {
        setName(event.target.value.trim());
    }

    function handleSubmit(event) {
        event.preventDefault();
        setUploading(true);
        if (!name) {
            setHelperText('Name is required');
            setError(true);
            return;
        }
        var data = new FormData();
        data.append('name', name);
        data.append('visible', visible);
        data.append('coverImage', coverImage);

        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted);
            }
        };
        addPlaylist(data, config).then(response => {
            const playlistUri = "/playlist/" + response.data.id;
            return navigate({pathname: playlistUri}, {replace: false});
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                console.log(error.message);
            }
            setHelperText('Service unavailable');
            setError(true);
        }).finally(() => {
            setUploading(false);
        });
    }

    const onDrop = React.useCallback((acceptedFiles) => {
        imageResize(acceptedFiles[0], 460, 460, (resizedImageFile) => {
            setCoverImage(resizedImageFile);
            Object.assign(resizedImageFile, {
                preview: URL.createObjectURL(resizedImageFile)
            });
        });
    }, [])

    function handleVisibilityChange(event) {
        setVisible(event.target.checked);
    };

    return (
        <Box pb={{sm: '5em', xs: '10em'}}>
            <Container style={{marginBottom: "1em", marginTop: "1em"}}>
                <Typography variant="h5" mt={1} mb={2} component="div">Create Playlist</Typography>
                <FormControl error={error} variant="standard">
                    <Grid component="form" container spacing={2} onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="left">
                                <TextField id="outlined-basic" onInput={handleInputName}
                                           type="text"
                                           label="Name"
                                           variant="outlined"/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="left">
                                <ImageDropZone onDrop={onDrop} coverImage={coverImage}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                sx={{textTransform: 'none'}}
                                control={<Switch
                                    checked={visible}
                                    onChange={handleVisibilityChange}
                                />} label="Public"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent={"left"}>
                                <FormHelperText>{helperText}</FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="left">
                                <Button disabled={!(coverImage && name) || uploading}
                                        variant="contained" type="submit">
                                    {!uploading && ("Create")}
                                    {uploading && (
                                        <CircularProgressWithLabel value={progress}/>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormControl>
            </Container>
        </Box>
    );
}
