import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {useNavigate} from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SelectPlaylistModal({open, setOpen, account}) {
    let navigate = useNavigate();

    const handleClose = () => setOpen(false);

    const goToCreatePlaylist = () => {
      return navigate({pathname: '/create/playlist'}, {replace: false});
    };

    const handlePlaylistSelection = (event, index) => {
        const selectedPlaylist = account.playlists[index];
        return navigate({pathname: "/create/media/playlist/" + selectedPlaylist.id}, {replace: false});
    };

    return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {(account.playlists && account.playlists.length > 0) && (
            <React.Fragment>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Select a Playlist
                </Typography>
                <List
                    sx={{
                        mt: 2,
                        width: '100%',
                        maxHeight: 400,
                        overflow: 'auto',
                        bgcolor: 'background.paper'
                     }}
                >
                    {account.playlists.map((playlist, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton
                                onClick={(event) => handlePlaylistSelection(event, index)}
                            >
                                <ListItemText id="switch-list-label-wifi" primary={playlist.name}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </React.Fragment>
        )}
        {(account.playlists && account.playlists.length === 0) && (
            <React.Fragment>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add Media requires a playlist.
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Button sx={{justifyContent: "inherit"}} size="small" onClick={goToCreatePlaylist}>
                        <PlaylistAddIcon/>
                        <span style={{paddingLeft: '1em'}}>Create Playlist</span>
                    </Button>
                </Typography>
            </React.Fragment>
        )}
        </Box>
      </Modal>
    </div>
    );
}