import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {ApplicationGlobals} from "./App";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
    addPlaylistToFavorites,
    addRecentlyListened,
    getPlaylist,
    patchLastMediaPlaylist,
    patchMediasPlaylist,
    patchPlaylist
} from "./api/PlaylistService";
import {Avatar, Chip, ListItemAvatar, Tooltip} from "@mui/material";
import * as PageConstants from "./PageConstants";
import {MusicNote} from "@mui/icons-material";
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Duration from "./Duration";
import TinyText from "./TinyText";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AlertMessage from "./AlertMessage";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditableMedia from "./EditableMediaList";
import {patchMedia} from "./api/MediaService";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import {formatArtistAlbum} from "./CommonFormats";
import TrackMoreMenu from "./TrackMoreMenu";
import {buildPlayerInfo} from "./MediaControl";

const PlaylistPage = ({selectedIndex, setSelectedIndex, lastFocusedMediaPublicId, setLastFocusedMediaPublicId}) => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [playlistState, setPlaylistState] = React.useState(null);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [favoriteButtonUnlocked, setFavoriteButtonUnlocked] = React.useState(true);
    const [editState, setEditState] = React.useState(false);
    const [mediaList, setMediaList] = React.useState([]);
    const [mediasCoverToUpdate, setMediasCoverToUpdate] = React.useState({});
    const [playlistOwner, setPlaylistOwner] = React.useState(false);
    const [playlistVisible, setPlaylistVisible] = React.useState(false);
    const [playlistName, setPlaylistName] = React.useState('');
    const player = applicationGlobals.contexts.player;
    const playlist = applicationGlobals.contexts.playlist;
    const navigate = useNavigate();

    const {id} = useParams();

    function buildMediaMetaData(medias) {
        if (medias) {
            for (let i = 0; i < medias.length; i++) {
                medias[i].playerInfo = buildPlayerInfo(medias[i], i);
                const randomId = Math.random().toString(36).slice(2, 4);
                medias[i].draggableId = 'id_' + (i + randomId);
            }
        }
    }

    function loadPlaylist(id) {
        getPlaylist(id).then(result => {
            if (!result.data) {
                setPlaylistState(null);
                console.log("playlist unavailable");
                return null;
            }
            const playlistResponse = result.data;
            buildMediaMetaData(playlistResponse.medias);
            setPlaylistState(playlistResponse);
            setLastFocusedMediaPublicId(playlistResponse.lastFocusedMediaPublicId);
            setMediaList(playlistResponse.medias);
            setPlaylistOwner(playlistResponse.ownerId === applicationGlobals.contexts.auth.authState.accountId);
            setPlaylistVisible(playlistResponse.visible);
            setPlaylistName(playlistResponse.name);
            playlist.setPlaylistState({
                visited: playlistResponse
            })
            if (player.playerState.currentPlaylist?.id !== playlistResponse.id) {
                setSelectedIndex(null);
            }

            if (player.playerState.currentTrack && playlistResponse.medias.length > 0) {
                for (var i = 0; i < playlistResponse.medias.length; i++) {
                    if (player.playerState.currentTrack.id === playlistResponse.medias[i].id) {
                        setSelectedIndex(i);
                    }
                }
            }
        }).catch(error => {
            console.log(error);
            setPlaylistState(null);
        });
    }

    React.useEffect(() => {
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.PLAYLIST);
        loadPlaylist(id);
    }, [id, applicationGlobals.contexts.auth.authState]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        const currentTrack = mediaList[index];
        if (applicationGlobals.contexts.auth.authState.loggedIn) {
            setLastFocusedMediaPublicId(currentTrack.id);
            patchLastMediaPlaylist(playlistState.id, currentTrack.id);
        }
        currentTrack.playerInfo = buildPlayerInfo(currentTrack, index, handleListItemClick);
        player.setPlayerState({
            currentTrack: currentTrack,
            currentPlaylist: playlistState,
            currentPlaylistMedia: mediaList,
        });
        addRecentlyListened(id);
    };

    const handleShareClick = (event) => {
        const playlistUri = window.location.href;
        if (navigator.share) {
            navigator.share({
                title: 'Share ' + playlistState.name,
                url: playlistUri
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            navigator.clipboard.writeText(playlistUri)
                .then(() => {
                    setAlertOpen(true);
                    setAlertMessage("Playlist link has been copied!");
                }).catch(console.error);
        }
    }

    const handleFavoriteClick = (event) => {
        setFavoriteButtonUnlocked(false);
        addPlaylistToFavorites(id).then(response => {
            setAlertOpen(true);
            setAlertMessage("Playlist copied to your account!");
            return navigate({pathname: "/playlist/" + response.data.id}, {replace: false});
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else {
                console.log(error.message);
            }
            setFavoriteButtonUnlocked(true);
        });
    }

    function handleEnterEditState() {
        setEditState(true);
    }

    function handlePlaylistInputName(event) {
        setPlaylistName(event.target.value);
    }

    function handlePlaylistVisibilityChange(event) {
        setPlaylistVisible(event.target.checked);
    }

    function handleCancelEdit() {
        setEditState(false);
        loadPlaylist(id);
        setMediasCoverToUpdate({});
    }

    function handleSaveEditChanges() {
        setEditState(false);

        async function updatePlaylist() {
            const data = new FormData();
            data.append('name', playlistName);
            data.append('visible', playlistVisible);
            const response = await patchPlaylist(id, data).catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(error.message);
                }
            });
        }

        async function updateMediasInPlaylist() {
            const response = await patchMediasPlaylist(id, mediaList).catch(error => {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(error.message);
                }
            });
        }

        async function updateMediasCovers() {
            for (let i = 0; i < mediaList.length; i++) {
                const media = mediaList[i];
                if (mediasCoverToUpdate[media.id]) {
                    const data = new FormData();
                    data.append('coverImage', media.coverImage);

                    const response = await patchMedia(media.id, data).catch(error => {
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else {
                            console.log(error.message);
                        }
                    });
                }
            }
        }

        updateMediasInPlaylist().then(() => {
            updatePlaylist().then(r => {
                updateMediasCovers().then(() => {
                    loadPlaylist(id);
                })
            });
        })

        setMediasCoverToUpdate({});
    }

    if (playlistState != null) {
        return (
            <Container sx={{marginBottom: {sm: '5em', xs: '10em'}}}>
                <Typography variant="h5" mt={1} mb={2} component="div">
                    <Stack direction="row" style={{paddingBottom: '0.5em', marginBottom: '0.5em'}}>
                        {!editState && (
                            <span>{playlistState.name}&nbsp;
                                <TinyText component={Link} to={"/account/" + playlistState.ownerId} sx={{textDecoration: 'none', color: 'white', paddingTop: '1em', display: 'inline-block'}}>
                                    by {playlistState.ownerName}
                                </TinyText>
                            </span>
                        )}
                        {!editState && playlistOwner && (
                            <Tooltip title="Edit Playlists" sx={{marginLeft: 1}}>
                                <IconButton size="small" color="inherit" onClick={handleEnterEditState}>
                                    <SettingsIcon sx={{color: 'gray'}}/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {editState && (
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <TextField id="outlined-basic" label="Name" variant="outlined" value={playlistName}
                                           onInput={handlePlaylistInputName}/>
                                <FormControlLabel
                                    sx={{textTransform: 'none'}}
                                    control={<Switch
                                        checked={playlistVisible}
                                        onChange={handlePlaylistVisibilityChange}
                                    />} label="Public"
                                />
                                <Tooltip title="Cancel Changes" sx={{marginLeft: 1}}>
                                    <IconButton size="small" color="inherit" onClick={handleCancelEdit}>
                                        <CloseIcon sx={{color: 'red'}}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save Changes" sx={{marginLeft: 1}}>
                                    <IconButton size="small" color="inherit" onClick={handleSaveEditChanges}>
                                        <CheckIcon sx={{color: 'green'}}/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>
                    {!editState && (
                        <Stack direction="row">
                            {playlistState.visible && (
                                <TinyText>
                                    <Chip icon={<ShareIcon/>}
                                          sx={{paddingLeft: 1, paddingRight: 1, marginLeft: 0, textTransform: 'none'}}
                                          component={Button} onClick={(event) => handleShareClick(event)}
                                          label="Share"
                                          variant="outlined" clickable/>
                                </TinyText>
                            )}
                            {!playlistOwner && applicationGlobals.contexts.auth.authState.loggedIn && (
                                <TinyText>
                                    <Chip icon={<ContentCopyIcon/>}
                                          sx={{paddingLeft: 1, paddingRight: 1, marginLeft: 1, textTransform: 'none'}}
                                          component={Button} onClick={(event) => handleFavoriteClick(event)}
                                          label="Clone"
                                          disabled={!favoriteButtonUnlocked}
                                          variant="outlined" clickable/>
                                </TinyText>
                            )}
                        </Stack>
                    )}
                </Typography>
                {!editState && (
                    <List
                        sx={{width: '100%', bgcolor: 'background.paper'}}
                    >
                        {mediaList.map((song, index) => ((song.pending && playlistOwner) || !song.pending) && (
                            <ListItem key={index} disablePadding>
                                <ListItemButton
                                    disabled={song.pending}
                                    selected={selectedIndex === index}
                                    onClick={(event) => handleListItemClick(event, index)}
                                    sx={{paddingTop: "0.8em", paddingBottom: "0.8em"}}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt={`${song.name}`}
                                                src={song.coverImageUri ? process.env.REACT_APP_BASE_URL + '/files/image/' + song.coverImageUri : null}
                                                variant={'square'}>
                                            <MusicNote/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    { song.id === lastFocusedMediaPublicId && (
                                        <Tooltip title="Previously played">
                                            <span style={{color: 'green', paddingRight: '1em'}}>●</span>
                                        </Tooltip>
                                    )}
                                    <ListItemText id="switch-list-label-wifi" primary={!song.pending ? song.name: 'Pending source: ' + song.name}
                                                  secondary={formatArtistAlbum(song)}/>
                                    <TinyText sx={{display: !song.pending ? 'block' : 'none', marginLeft: 1}}>
                                        <Duration seconds={song.duration}/>
                                    </TinyText>
                                </ListItemButton>
                                <TrackMoreMenu buildMediaMetaData={buildMediaMetaData} mediaList={mediaList} setMediaList={setMediaList} media={song} showMediaRemove={playlistOwner}/>
                            </ListItem>
                        ))}
                    </List>
                )}
                {editState && (
                    <EditableMedia
                        mediaList={mediaList}
                        setMediaList={setMediaList}
                        mediasCoverToUpdate={mediasCoverToUpdate}
                        mediaListUploaded={{}}
                        mediaListUploadPercent={null}
                        uploading={false}
                        removable={true}
                    />
                )}
                <AlertMessage open={alertOpen} setOpen={setAlertOpen} message={alertMessage} time={3000}/>
            </Container>
        );
    }
}

export default PlaylistPage;
