import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TinyText from "./TinyText";
import Duration from "./Duration";
import * as React from "react";
import {formatArtistAlbum} from "./CommonFormats";
import {getMedias} from "./api/MediaService";
import {Avatar, ListItemAvatar} from "@mui/material";
import {MusicNote} from "@mui/icons-material";
import TrackMoreMenu from "./TrackMoreMenu";
import {ApplicationGlobals} from "./App";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Link, useParams} from "react-router-dom";
import * as PageConstants from "./PageConstants";
import {buildPlayerInfo} from "./MediaControl";

export default function MediaList({selectedIndex, setSelectedIndex}) {
    const [mediaList, setMediaList] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const player = applicationGlobals.contexts.player;
    const {id} = useParams();

    React.useEffect(() => {
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.ALL_MEDIA);
        getMedias().then(response => {
            buildMediaMetaData(response.data.medias);
            setMediaList(response.data);
        }).finally(() => {
            setLoading(false);
        });

        return () => {};
    }, []);

    function buildMediaMetaData(medias) {
        if (medias) {
            for (let i = 0; i < medias.length; i++) {
                medias[i].playerInfo = buildPlayerInfo(medias[i], i, handleListItemClick);
                const randomId = Math.random().toString(36).slice(2, 4);
                medias[i].draggableId = 'id_' + (i + randomId);
            }
        }
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        const currentTrack = mediaList[index];
        currentTrack.playerInfo = buildPlayerInfo(currentTrack, index, handleListItemClick);
        player.setPlayerState({
            currentTrack: currentTrack,
            currentPlaylist: null,
            currentPlaylistMedia: mediaList,
        });
    };

    return (
        <Container sx={{marginBottom: {sm: '5em', xs: '10em'}}}>
            <Typography variant="h5" mt={1} mb={2} component="div">
                <Stack direction="row" style={{paddingBottom: '0.5em', marginBottom: '0.5em'}}>
                    <span>All Media</span>
                </Stack>
            </Typography>
            {loading && (
                <TinyText sx={{textDecoration: 'none', color: 'white', paddingTop: '1em', display: 'inline-block'}}>
                    Loading...
                </TinyText>
            )}
            {!loading && mediaList.length === 0 && (
                <span>
                    <TinyText component={Link} to={"/"} sx={{textDecoration: 'none', color: 'white', paddingTop: '1em', display: 'inline-block'}}>
                        No Media found. Click here to get started!
                    </TinyText>
                </span>
            )}
            <List
                sx={{width: '100%', bgcolor: 'background.paper'}}
            >
                {mediaList.map((song, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}
                            sx={{paddingTop: "0.8em", paddingBottom: "0.8em"}}
                        >
                            <ListItemAvatar>
                                <Avatar alt={`${song.name}`}
                                        src={song.coverImageUri ? process.env.REACT_APP_BASE_URL + '/files/image/' + song.coverImageUri : null}
                                        variant={'square'}>
                                    <MusicNote/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText id="switch-list-label-wifi" primary={song.name}
                                          secondary={formatArtistAlbum(song)}/>
                            <TinyText sx={{display: 'block', marginLeft: 1}}>
                                <Duration seconds={song.duration}/>
                            </TinyText>
                        </ListItemButton>
                        <TrackMoreMenu buildMediaMetaData={buildMediaMetaData} mediaList={mediaList} setMediaList={setMediaList} media={song} showMediaRemove={false}/>
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

