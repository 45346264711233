import SvgIcon from '@mui/material/SvgIcon';
import {ReactComponent as Logo} from "./svg/shurapleer_logo_icon.svg";
import ShuraPleerLogo from "./svg/shurapleer_logo_full.svg";
import ShuraPleerContent from "./svg/shurapleer_first page_mobile.png";
import Box from '@mui/material/Box';

export function ShuraIconFull(props) {
    return (
        <Box component="img" {...props} src={`${ShuraPleerLogo}`} alt="Shura player logo"/>
    );
}

export function ShuraIcon(props) {
    return (
        <SvgIcon {...props}>
            <Logo/>
        </SvgIcon>
    );
}

export function ShuraContent(props) {
    return (
        <Box component="img" {...props} src={`${ShuraPleerContent}`} alt="Shura player logo"/>
    );
}
