import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LogtoClient from '@logto/browser';

const root = ReactDOM.createRoot(document.getElementById('root'));

export const LogtoGlobals = React.createContext(null);

root.render(
    <LogtoGlobals.Provider value={{
        client: new LogtoClient({
            endpoint:  process.env.REACT_APP_AUTH_URL,
            appId: process.env.REACT_APP_APP_ID,
            resources: [process.env.REACT_APP_RESOURCE]
        })
    }}>
        <App/>
    </LogtoGlobals.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
