import * as React from "react";
import {addMediaCopyToPlaylist} from "./api/PlaylistService";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {useParams} from "react-router-dom";

export default function AddToPlaylistModal({buildMediaMetaData, mediaList, setMediaList, playlists, open, setOpen, setAlertOpen, setAlertMessage, media}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const {id} = useParams();

    const handleClose = () => setOpen(false);

    function handleAddMedia(medias) {
        let newList = [];
        for (let i = 0; i < mediaList.length; i++) {
            newList.push(mediaList[i]);
        }
        newList.push(medias[medias.length - 1]);
        buildMediaMetaData(newList);
        setMediaList(newList);
    }

    const handleAdd = (event, index) => {
        const selectedPlaylist = playlists[index];

        addMediaCopyToPlaylist(selectedPlaylist.id, media.id).then(playlistResponse => {
            setAlertMessage(`Media "${media.name}" copied to "${selectedPlaylist.name}" playlist.`);
            if (id === playlistResponse.data.id) {
                handleAddMedia(playlistResponse.data.medias);
            }
        }).finally(() => {
            setAlertOpen(true);
            setOpen(false);
        });
    };

    if (playlists != null) {
        return (
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <React.Fragment>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Select a Playlist
                            </Typography>
                            <List
                                sx={{
                                    mt: 2,
                                    width: '100%',
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    bgcolor: 'background.paper'
                                }}
                            >
                                {playlists.map((playlist, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton
                                            onClick={(event) => handleAdd(event, index)}
                                        >
                                            <ListItemText id="switch-list-label-wifi" primary={playlist.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </React.Fragment>
                    </Box>
                </Modal>
            </div>
        );
    }
}
