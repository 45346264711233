import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import {ContentCopy} from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import AddToPlaylistModal from "./AddToPlaylistModal";
import AlertMessage from "./AlertMessage";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmationModal from "./ConfirmationModal";
import {deleteMediaFromPlaylist} from "./api/PlaylistService";
import {useNavigate, useParams} from "react-router-dom";
import {getAccount} from "./api/AccountService";
import {ApplicationGlobals} from "./App";
import * as PageConstant from "./PageConstants";

export default function TrackMoreMenu({buildMediaMetaData, mediaList, setMediaList, media, showMediaRemove}) {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [selectPlaylistOpen, setSelectPlaylistOpen] = React.useState(false);
    const [removeModalOpen, setRemoveModalOpen] = React.useState(false);
    const [alertOpen, setAlertOpen] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState(null);
    const [moreMenu, setMoreMenu] = React.useState(null);
    const [playlists, setPlaylists] = React.useState(null);
    const {id} = useParams();
    let navigate = useNavigate();
    const handleClick = (event) => {
        setMoreMenu(event.currentTarget);
    };

    const handleCopy = () => {
        setMoreMenu(null);
        copyAction(media);
    };

    const handleOpen = () => {
        if (applicationGlobals.contexts.page.currentPage === PageConstant.PLAYLIST) {
            return navigate({pathname: '/playlist/' + id + '/media/' + media.id}, {replace: false});
        } else {
            return navigate({pathname: '/media/' + media.id}, {replace: false});
        }
    };

    const handleRemove = () => {
        if (id) {
            deleteMediaFromPlaylist(id, media.id).then(result => {
                handleRemoveMedia();
            }).finally(() => {
                setRemoveModalOpen(false);
            });
        }
    }

    function handleRemoveMedia() {
        let trimmedList = [];
        for (let i = 0; i < mediaList.length; i++) {
            if (mediaList[i].id != media.id) {
                trimmedList.push(mediaList[i]);
            }
        }
        buildMediaMetaData(trimmedList);
        setMediaList(trimmedList);
    }

    const promptRemove = () => {
        setRemoveModalOpen(true);
    }

    const handleClose = () => {
        setMoreMenu(null);
    }

    function copyAction(media) {
        getAccount(applicationGlobals.contexts.auth.authState?.accountId).then(response => {
            setPlaylists(response.data.playlists);
            setSelectPlaylistOpen(true);
        })
    }

    return (
        <React.Fragment>
            <Tooltip title="Track Menu">
                <IconButton
                    aria-controls={moreMenu ? 'track-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={moreMenu ? 'true' : undefined}
                    aria-label="track menu"
                    sx={{marginLeft: 1}}
                    onClick={handleClick}
                >
                    <MoreVertTwoToneIcon/>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={moreMenu}
                id="account-menu"
                open={!!moreMenu}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleOpen}>
                    <ListItemIcon>
                        <ArrowOutwardIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Open</ListItemText>
                </MenuItem>
                {applicationGlobals.contexts.auth.authState.loggedIn && (
                    <MenuItem onClick={handleCopy}>
                        <ListItemIcon>
                            <ContentCopy fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Copy</ListItemText>
                    </MenuItem>
                )}
                {showMediaRemove && (
                    <MenuItem onClick={promptRemove}>
                        <ListItemIcon>
                            <DeleteOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Remove</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <AddToPlaylistModal buildMediaMetaData={buildMediaMetaData} mediaList={mediaList} setMediaList={setMediaList} playlists={playlists} open={selectPlaylistOpen} setOpen={setSelectPlaylistOpen} setAlertOpen={setAlertOpen} setAlertMessage={setAlertMessage} media={media}/>
            <ConfirmationModal open={removeModalOpen} setOpen={setRemoveModalOpen} actionTitle={"Remove the following?"} actionMessage={media.name} acceptCallback={handleRemove}/>
            <AlertMessage open={alertOpen} setOpen={setAlertOpen} message={alertMessage} time={3000}/>
        </React.Fragment>
    );
}