import * as React from "react";
import {ApplicationGlobals} from "./App";
import * as PageConstants from "./PageConstants";
import {Container} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import ScrollingText from "./ScrollingText";

const ResumeBar = ({resumeMedia}) => {
    const applicationGlobals = React.useContext(ApplicationGlobals);
    const [resume, setResume] = React.useState(false);
    const [resumeUrl, setResumeUrl] = React.useState(null);
    React.useEffect(() => {
        if (applicationGlobals.contexts.page.currentPage == PageConstants.MEDIA) {
            setResume(false);
            return;
        }
        setResume(resumeMedia.media != null);
        if (resumeMedia.media) {
            if (resumeMedia.playlist) {
                setResumeUrl("/playlist/" + resumeMedia.playlist.id + "/media/" + resumeMedia.media.id + "?play=true");
            } else {
                setResumeUrl("/media/" + resumeMedia.media.id + "?play=true");
            }
        }
    }, [resumeMedia.media]);

    if(resumeMedia.media && resume) {
        return (
            <Container disableGutters>
                <Grid container>
                    <Grid item={true} xs={12}>
                        <Button component={Link} onClick={() => setResume(false)} to={resumeUrl} style={{textDecoration: 'none', color: 'inherit', padding: '0', width: '100%'}}>
                            <ScrollingText text={resumeMedia.media.name} text2={resumeMedia.media.artist ? (' - ' + resumeMedia.media.artist) : ''}/>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default ResumeBar;