
export function formatArtistAlbum(song) {
    if (song.artist && song.album) {
        return `${song.artist} · ${song.album}`;
    }
    if (song.artist && !song.album) {
        return `${song.artist}`;
    }
    if (!song.artist && song.album) {
        return `${song.album}`;
    }
    return '';
}