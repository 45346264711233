import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;

    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 2rem;
        height: 100%;
        z-index: 1;
    }
    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
`;

const Marquee = styled.span`
    display: inline-block;
    padding-left: 50%;
    animation: marquee 15s linear infinite;

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
`;

const ScrollingText = ({ text, text2 = '' }) => {
    return (
        <Wrapper style={{marginLeft:'1em', marginRight:'1em'}}>
            <Marquee>{text}{text2}</Marquee>
            <Marquee>{text}{text2}</Marquee>
        </Wrapper>
    );
};

export default ScrollingText;
