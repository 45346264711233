import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {CardActionArea, Chip, Container, Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import {Link, useParams} from "react-router-dom";
import {ApplicationGlobals} from "./App";
import {getAccount, isFollowing, toggleFollowing} from "./api/AccountService.js";
import * as PageConstants from "./PageConstants";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {deletePlaylist, patchPlaylist} from "./api/PlaylistService";
import DeleteIcon from "@mui/icons-material/Delete";
import imageResize from "./ImageResize";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import Button from "@mui/material/Button";

const PlaylistCard = ({playlist, playlistsToUpdate, playlistToRemove, editing}) => {
    const [name, setName] = React.useState(playlist.name || '');
    const [visible, setVisible] = React.useState(false);
    const [render, setRender] = React.useState(true);
    const [coverImageFile, setCoverImageFile] = React.useState(playlist.coverImageFile || null);
    const [imageUrl, setImageUrl] = React.useState(playlist.coverImage);

    const playlistUri = "/playlist/" + playlist.id;

    React.useEffect(() => {
        if (playlist.name !== name) {
            setName(playlist.name);
        }
        setImageUrl(playlist.coverImage);
        setVisible(playlist.visible);
        setRender(true);
        if (coverImageFile) {
            // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
            return () => {
                URL.revokeObjectURL(coverImageFile);
                setCoverImageFile(null);
            }
        }
    }, [playlist, editing]);

    function handleInputName(event) {
        playlist.name = event.target.value;
        playlistsToUpdate[playlist.id] = true;
        setName(playlist.name);
    }

    function handleVisibilityChange(event) {
        playlist.visible = event.target.checked;
        playlistsToUpdate[playlist.id] = true;
        setVisible(playlist.visible);
    }

    function handleInputCover(event) {
        imageResize(event.target.files[0], 460, 460, (resizedImageFile) => {
            playlist.coverImageFile = resizedImageFile;
            playlistsToUpdate[playlist.id] = true;
            setCoverImageFile(playlist.coverImageFile);
        });
    }

    function handleRemovePlaylist() {
        playlistToRemove.push(playlist.id);
        setRender(false);
    }

    if (render) {
        return (
            <Card>
                <CardActionArea component={!editing ? Link : 'div'} to={playlistUri}>
                    {editing && (
                        <React.Fragment>
                            <input
                                hidden
                                accept=".jpeg,.jpg,.png,.tiff"
                                id={`${'cover-' + playlist.id}`}
                                onChange={handleInputCover}
                                multiple
                                type="file"
                            />
                            <label htmlFor={`${'cover-' + playlist.id}`}>
                                {coverImageFile && (
                                    <CardMedia
                                        component="img"
                                        sx={{ height: {xs: "160px", sm: "240px"}}}
                                        alt={`${playlist.name}`}
                                        src={URL.createObjectURL(coverImageFile)}
                                    />
                                )}
                                {!coverImageFile && (
                                    <CardMedia
                                        component="img"
                                        sx={{ height: {xs: "160px", sm: "240px"}}}
                                        image={imageUrl ? process.env.REACT_APP_BASE_URL + '/files/image/' + imageUrl : null}
                                        alt={playlist.name + ' playlist cover'}
                                    />
                                )}
                            </label>
                        </React.Fragment>
                    )}
                    {!editing && (
                        <CardMedia
                            component="img"
                            sx={{ height: {xs: "160px", sm: "240px"}}}
                            image={imageUrl ? process.env.REACT_APP_BASE_URL + '/files/image/' + imageUrl : null}
                            alt={playlist.name + ' playlist cover'}
                        />
                    )}
                    <CardContent>
                        <Typography gutterBottom noWrap component="div">
                            {editing && (
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <TextField id="outlined-basic" label="Name" variant="outlined" value={name}
                                               onInput={handleInputName}/>
                                    <IconButton aria-label="delete"
                                                onClick={handleRemovePlaylist}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Stack>
                            )}
                            {!editing && (
                                playlist.name
                            )}
                        </Typography>
                        {editing && (
                            <FormControlLabel
                                sx={{textTransform: 'none'}}
                                control={<Switch
                                    checked={visible}
                                    onChange={handleVisibilityChange}
                                />} label="Public"
                            />
                        )}

                        {!editing && (
                            <Typography variant="body2" color="text.secondary">
                                Total {playlist.totalMedia}
                            </Typography>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}

export default function AccountPage() {
    const applicationGlobals = React.useContext(ApplicationGlobals);

    const {id} = useParams();
    const [accountState, setAccountState] = React.useState(null);
    const [playlistsState, setPlaylistsState] = React.useState(null);
    const [editState, setEditState] = React.useState(false);
    const [playlistsToUpdate, setPlaylistsToUpdate] = React.useState({});
    const [playlistToRemove, setPlaylistToRemove] = React.useState([]);
    const [following, setFollowing] = React.useState(null);
    const accountOwned = applicationGlobals.contexts.auth.authState.accountId === id;

    function loadPlaylists(id) {
        getAccount(id).then(result => {
            setAccountState(result.data);
            setPlaylistsState(result.data.playlists);
            if (applicationGlobals.contexts.auth.authState.loggedIn && !accountOwned) {
                isFollowing(id).then(r => {
                    setFollowing(r.data);
                })
            }
        }).catch(error => {
            console.log(error);
            setAccountState(null);
        });
    }
    React.useEffect(() => {
        applicationGlobals.contexts.page.setCurrentPage(PageConstants.ACCOUNT);
        loadPlaylists(id);
    }, [id]);

    function handleEnterEditState() {
        setEditState(true);
    }

    function handleFollowAccount(event) {
        toggleFollowing(id).then(r => {
            setFollowing(r.data);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleCancelEdit() {
        setEditState(false);
        loadPlaylists(id);
        setPlaylistsToUpdate({});
        setPlaylistToRemove([]);
    }

    function handleSaveEditChanges() {
        setEditState(false);

        async function updatePlaylists() {
            for (let i = 0; i < playlistsState.length; i++) {
                const playlist = playlistsState[i];
                if (playlistsToUpdate[playlist.id]) {
                    const data = new FormData();
                    data.append('name', playlist.name);
                    data.append('visible', playlist.visible);
                    data.append('coverImage', playlist.coverImageFile);
                    const response = await patchPlaylist(playlist.id, data).catch(error => {
                        if (error.response) {
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else {
                            console.log(error.message);
                        }
                    });
                }
            }
        }

        async function deletePlaylists() {
            for (let i = 0; i < playlistToRemove.length; i++) {
                const playlistId = playlistToRemove[i];
                const response = await deletePlaylist(playlistId).catch(error => {
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else {
                        console.log(error.message);
                    }
                });
            }
        }

        updatePlaylists().then(()=> {
            deletePlaylists().then(()=> {
                loadPlaylists(id);
            })
        });

        setPlaylistsToUpdate({});
        setPlaylistToRemove([]);
    }

    if (accountState != null) {
        return (
            <Container style={{marginBottom: "1em", marginTop: "1em"}}>
                <Typography variant="h5" mt={1} mb={2} component="div">
                    <Stack direction="row">
                        <span> {editState && ('Editing ')}
                            {!accountOwned && (
                                <React.Fragment>
                                    {accountState.name}s&nbsp;
                                </React.Fragment>
                            )}
                            {accountOwned && (
                                <React.Fragment>
                                    Your&nbsp;
                                </React.Fragment>
                            )}
                            Playlists
                        </span>
                        {!accountOwned && applicationGlobals.contexts.auth.authState.loggedIn && following !== null && (
                            <Tooltip title={following ? `Unfollow ${accountState.name} playlists` : `Follow ${accountState.name} playlists`} sx={{marginLeft: 1}}>
                                <Chip icon={following ? <PersonRemoveIcon/> : <PersonAddIcon/>}
                                      sx={{paddingLeft: 1, paddingRight: 1, marginLeft: 2, textTransform: 'none'}}
                                      component={Button} onClick={(event) => handleFollowAccount(event)}
                                      label={following ? "Unfollow" : "Follow"}
                                      variant={following ? "filled" : "outlined"} clickable/>
                            </Tooltip>
                        )}
                        {!editState && accountOwned && (playlistsState.length !== 0) && (
                            <Tooltip title="Edit Playlists" sx={{marginLeft: 1}}>
                                <IconButton size="small" color="inherit" onClick={handleEnterEditState}>
                                    <SettingsIcon sx={{color: 'gray'}}/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {editState && (
                            <React.Fragment>
                                <Tooltip title="Cancel Changes" sx={{marginLeft: 1}}>
                                    <IconButton size="small" color="inherit" onClick={handleCancelEdit}>
                                        <CloseIcon sx={{color: 'red'}}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save Changes" sx={{marginLeft: 1}}>
                                    <IconButton size="small" color="inherit" onClick={handleSaveEditChanges}>
                                        <CheckIcon sx={{color: 'green'}}/>
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        )}
                    </Stack>
                </Typography>

                <Box sx={{flexGrow: 1}} pb={{sm: '5em', xs: '10em'}}>
                    <Grid container spacing={{xs: 2, md: 2}} columns={{xs: 2, sm: 3, md: 4}}>
                        {playlistsState.map((r, index) => (
                            <Grid item xs={1} key={index}>
                                <PlaylistCard playlist={r} playlistsToUpdate={playlistsToUpdate} playlistToRemove={playlistToRemove} editing={editState}/>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        );
    }
}
